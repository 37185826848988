import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import ImageViewer from '../ImageViewer/ImageViewer';
import * as Mapper from '../../helper/Mappers';
import { HeadingComponent, ButtonComponent, TableHeadingContent, ModalComponent, TableHeadCells, SimpleCheckbox, LinkComponent } from '../Reusable/';
import { Grid, TextareaAutosize, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { ApplicationModelFactory } from './Application/ApplicationModel';
import * as Fmt from '../../helper/Formatters';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../helper/GlobalVariables';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel"
import { Edit } from '@mui/icons-material';
import { DirtyStore } from '../../store';
import { getLocale } from '../../helper/CountryLanguages';
import './MACAC.css';

export default function MACAC(props) {
    const { denyEdit } = props;
    var macacUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "machine-analysis";

    const { t } = useTranslation();
    let { id } = useParams();
    const { country } = getLocale();
    let history = useHistory();

    const [modalModel, setModalModel] = useState({ open: false, message: "" });
    const [comment, setComment] = useState("");
    const [mainCalcModel, setMainCalcModel] = useState({});
    const [filterDataApplication, setFilterDataApplication] = useState([])
    const [applicationList, setApplicationList] = useState([]);
    const [applicationsMarkedForDeletion, setApplicationsMarkedForDeletion] = useState([]);
    const [applicationListRowsPerPage, setApplicationListRowsPerPage] = useState(10);
    const [applicationListCurrentPage, setApplicationListCurrentPage] = useState(0);

    const applicationModel = ApplicationModelFactory(t);

    useEffect(() => {
        if (id) {
            loadExistingData(true);
        }
    }, [id]);

    const clickCancel = () => {
        history.push(`/projectsandrequests`);
    }

    const isCheckedForDeletion = id => applicationsMarkedForDeletion.some(x => x == id);

    const checkForDeletion = id => {
        let isAlreadyChecked = isCheckedForDeletion(id);
        let checkedArray = isAlreadyChecked ? applicationsMarkedForDeletion.filter(x => x != id) : [...applicationsMarkedForDeletion, id];
        setApplicationsMarkedForDeletion(checkedArray);
    }

    const clickDelete = () => {
        if (applicationsMarkedForDeletion == undefined || applicationsMarkedForDeletion.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal();
    }

    const clickDeleteOpenModal = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = () => deleteTableLinePost();
        setModalModel({ ...modalModel });
    }

    const deleteTableLinePost = () => {
        let postModel = {
            ProjectId: id,
            IndexArray: applicationsMarkedForDeletion
        };

        API.post(`${macacUrl}/${id}/Application`, postModel).then(() => {
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
            setApplicationsMarkedForDeletion([]);
            loadExistingData();
        })
    }

    const postMacac = () => {
        let postModel = {
            projectId: id,
            Comment: comment
        };

        API.put(macacUrl + '/' + id, postModel).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            loadExistingData();
        })
    }

    const loadExistingData = (initialValue) => {
        API.get(macacUrl + '/' + id).then(resp => {
            setMainCalcModel(resp.macacCalcResults);
            setComment(resp.comment);

            let mappedApplications = Mapper.mergeArrayModels(resp.applications, resp.macacCalcResults.singleAppCalcResults);
            enumerateTable(mappedApplications);
            setApplicationList(mappedApplications);
            setFilterDataApplication(mappedApplications);
            if (initialValue) ScrollerToTag()
        })
    }
    const enumerateTable = (table) => {
        table.forEach((line, index) => {
            line.no = index + 1;
        });
    }

    const addTableLine = () => pushHistoryForTableEdit(0);

    const pushHistoryForTableEdit = cid => history.push(getTableEditLink(cid));

    const getTableEditLink = cid => `/project/${id}/MACAC/Application/${cid}#Tabs`;

    const filterFunction = (tempdata) => {
        setApplicationList(tempdata);
    }

    return (
        <div className="macac table-layout-fix">
            <ModalComponent model={modalModel} />
            <Grid container spacing={5} direction="column">
                <Grid item>
                    <HeadingComponent value={t('Results')} size="h6" />
                </Grid>
                {Object.keys(mainCalcModel).length > 0 &&
                    <Grid item>
                        <TableContainer component={Paper} >
                            <Table aria-label="spanning table">
                                <TableHead>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('TOTAL')}</TableCell>
                                        <TableCell align="left">{t('Actual')}</TableCell>
                                        <TableCell align="left">{t('Future')}</TableCell>
                                        <TableCell align="left">{t('OpenSavings')}</TableCell>
                                        <TableCell align="left">{t('AchievedSavings')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('StaticConsumption') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationFlowActualTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationFlowActualTOTAL, country, 2)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationFlowFutureTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationFlowFutureTOTAL, country, 2)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationFlowSavingsTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationFlowSavingsTOTAL, country, 2)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationFlowAchievedSavingsTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationFlowAchievedSavingsTOTAL, country, 2)}</TableCell>
                                    </TableRow>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('VolumePerYear') + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationVolumeYearActualTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationVolumeYearActualTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationVolumeYearFutureTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationVolumeYearFutureTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationVolumeYearSavingsTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationVolumeYearSavingsTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationVolumeYearAchievedSavingsTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationVolumeYearAchievedSavingsTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationActualElectricityConsumption" align="left">{Fmt.round(mainCalcModel.optimizationActualElectricityConsumption, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationFutureElectricityConsumption" align="left">{Fmt.round(mainCalcModel.optimizationFutureElectricityConsumption, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationElectricityConsumptionSavingsOpen" align="left">{Fmt.round(mainCalcModel.optimizationElectricityConsumptionSavingsOpen, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationElectricityConsumptionSavingsAchieved" align="left">{Fmt.round(mainCalcModel.optimizationElectricityConsumptionSavingsAchieved, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('CO2') + Fmt.getUnitInBracket("co2Wheight", t)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCO2Actual" align="left">{Fmt.round(mainCalcModel.optimizationCO2Actual, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCO2Future" align="left">{Fmt.round(mainCalcModel.optimizationCO2Future, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCO2SavingsOpen" align="left">{Fmt.round(mainCalcModel.optimizationCO2SavingsOpen, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCO2SavingsAchieved" align="left">{Fmt.round(mainCalcModel.optimizationCO2SavingsAchieved, country, 0)}</TableCell>
                                    </TableRow>
                                    <TableRow className="table-head-row">
                                        <TableCell align="left">{t('CostPerYear') + Fmt.getCurrency()}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCostsActualTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationCostsActualTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationCostsFutureTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationCostsFutureTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationSavingsBeforeTaxesTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationSavingsBeforeTaxesTOTAL, country, 0)}</TableCell>
                                        <TableCell id="td_MCAC-optimizationSavingsBeforeTaxesAchievedTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationAchievedSavingsBeforeTaxesTOTAL, country, 0)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>
                }
                <Grid item id="#Application">
                    <HeadingComponent value={t('MA/CACPageTitle')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableHeadingContent
                        clickDelete={clickDelete}
                        clickAdd={addTableLine}
                        defaultData={applicationList}
                        filterData={filterDataApplication}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.Application}
                        denyEdit={denyEdit}
                        tableId={"Application"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    {!denyEdit && <TableCell></TableCell>}
                                    <TableHeadCells
                                        model={applicationModel}
                                        propertyList={["no", "measurementId", "building", "department", "machine", "optimizationFlowSavings", "optimizationVolumeYearSavings", "optimizationSavingsBeforeTaxes", "optimizationInvestment", "optimizationPaybackTime"]}
                                        tableName="Application"
                                        data={applicationList}
                                        setData={setApplicationList}
                                    />
                                    <TableCell></TableCell>
                                </TableRow >
                            </TableHead >
                            {applicationList && applicationList.length > 0 ?
                                <TableBody>
                                    {applicationList.slice(applicationListCurrentPage * applicationListRowsPerPage, applicationListCurrentPage * applicationListRowsPerPage + applicationListRowsPerPage).map((item, index) =>
                                        <TableRow key={"Application" + index}>
                                            {!denyEdit &&
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={"Application-checkbox" + index}
                                                        key={Math.random()}
                                                        defaultChecked={isCheckedForDeletion(item.id)}
                                                        color="default"
                                                        onChange={() => checkForDeletion(item.id)}>
                                                    </SimpleCheckbox>
                                                </TableCell>
                                            }
                                            <TableCell id={"td_Application-no" + index}>{item.no}</TableCell>
                                            <TableCell id={"td_Application-measurementId" + index}>
                                                <LinkComponent
                                                    link={getTableEditLink(item.id)}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_Application-building" + index}>{item.building}</TableCell>
                                            <TableCell id={"td_Application-department" + index}>{item.department}</TableCell>
                                            <TableCell id={"td_Application-machine" + index}>{item.machine}</TableCell>
                                            <TableCell id={"td_Application-optimizationFlowSavings" + index}>{Fmt.round(item.optimizationFlowSavings, country, 0)}</TableCell>
                                            <TableCell id={"td_Application-optimizationVolumeYearSavings" + index}>{Fmt.round(item.optimizationVolumeYearSavings, country, 0)}</TableCell>
                                            <TableCell id={"td_Application-optimizationSavingsBeforeTaxes" + index}>{Fmt.round(item.optimizationSavingsBeforeTaxes, country, 0)}</TableCell>
                                            <TableCell id={"td_Application-optimizationInvestment" + index}>{Fmt.round(item.optimizationInvestment, country, 0)}</TableCell>
                                            <TableCell id={"td_Application-optimizationPaybackTime" + index}>{Fmt.round(item.optimizationPaybackTime, country, 1)}</TableCell>
                                            <TableCell >
                                                <IconButton
                                                    id={"btn_Application-edit" + index}
                                                    href={getTableEditLink(item.id)}
                                                    onClick={() => pushHistoryForTableEdit(item.id)}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <div>{t('NoData')}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={applicationList && applicationList.length > 0 ? applicationList.length : 0}
                        rowsPerPage={applicationListRowsPerPage}
                        page={applicationListCurrentPage}
                        onPageChange={(e, page) => setApplicationListCurrentPage(page)}
                        onRowsPerPageChange={e => setApplicationListRowsPerPage(e.target.value)}
                    />
                </Grid>
                <Grid item id="#PaybackTime">
                    <HeadingComponent value={t('PaybackTime')} size="h6" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableBody>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('AnnualSavingsAfterTaxes') + Fmt.getCurrency()}</TableCell>
                                    <TableCell id="td_MCAC-optimizationSavingsAfterTaxesTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationSavingsAfterTaxesTOTAL, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell className="font-bold" align="left">{t('TotalInvestment') + Fmt.getCurrency()}</TableCell>
                                    <TableCell className="font-bold" id="td_MCAC-optimizationInvestmentTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationInvestmentTOTAL, country, 0)}</TableCell>
                                </TableRow>
                                <TableRow className="table-head-row">
                                    <TableCell align="left">{t('PaybackTimeInMonths')}</TableCell>
                                    <TableCell id="td_MCAC-optimizationPaybackTimeTOTAL" align="left">{Fmt.round(mainCalcModel.optimizationPaybackTimeTOTAL, country, 1)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        id='textarea_macac-comment'
                        type="text"
                        minRows="5"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.MACAC} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="macac-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="macac-save" value={t('Save')} color="primary" onChange={postMacac}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

