import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import ImageViewer from '../ImageViewer/ImageViewer';
import * as Mapper from '../../helper/Mappers';
import './AirNetwork.css';
import { HeadingComponent, ButtonComponent, SimpleCheckbox, TableHeadingContent, TableHeadCells, ModalComponent, LinkComponent, TextFieldComponent } from '../Reusable/';
import { Grid, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper, TextareaAutosize, IconButton, TablePagination } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { getLocale } from '../../helper/CountryLanguages';
import { AirNetworkModel } from './AirNetworkModel';
import { DistributionLineModelFactory } from './DistributionLine/DistributionLineModel';
import { AirReceiverModel } from './AirReceiver/AirReceiverModel';
import { PressureDropAnalysisModelFactory, PressureDropAnalysisTableFilterModelFactory } from './PressureDropAnalysis/PressureDropAnalysisModel';
import * as Fmt from '../../helper/Formatters';
import * as DateHelper from '../../helper/DateHelper';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../helper/GlobalVariables';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import CostSavingsTable from "../CostSavings/CostSavingTable/CostSavingTable";
import { Edit } from '@mui/icons-material';
import { DirtyStore } from '../../store';


export default function AirNetwork(props) {
    const { denyEdit } = props;
    const anUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-network-analysis";

    const { t } = useTranslation();
    let { id } = useParams();
    const { country } = getLocale();

    let history = useHistory();

    const [mainModel, setMainModel] = useState(AirNetworkModel(t));
    const [distLineList, setDistLineList] = useState([]);
    const [airReceiverList, setAirReceiverList] = useState([]);
    const [pressureDropList, setPressureDropList] = useState([]);
    const [filterDataReceiver, setFilterDataReceiver] = useState([]);
    const [filterDataPDAnalysis, setFilterDataPDAnalysis] = useState([]);
    const [filterDataDist, setFilterDataDist] = useState([]);
    const [airNetworkCalcs, setAirNetworkCalcs] = useState({});
    const [modalModel, setModalModel] = useState({ open: false, message: "" });
    const [distLineListRowsPerPage, setDistLineListRowsPerPage] = useState(10);
    const [distLineListCurrentPage, setDistLineListCurrentPage] = useState(0);
    const [airReceiverListRowsPerPage, setAirReceiverListRowsPerPage] = useState(10);
    const [airReceiverListCurrentPage, setAirReceiverListCurrentPage] = useState(0);
    const [distLineListMarkedForDeletion, setDistLineListMarkedForDeletion] = useState([]);
    const [airReceiverListMarkedForDeletion, setAirReceiverListMarkedForDeletion] = useState([]);
    const [pressureDropListMarkedForDeletion, setPressureDropListMarkedForDeletion] = useState([]);

    const distributionLineModel = DistributionLineModelFactory(t);
    const airReceiverModel = AirReceiverModel(t);
    const pressureDropModel = { ...PressureDropAnalysisModelFactory(t), ...PressureDropAnalysisTableFilterModelFactory(t) };

    useEffect(() => {
        if (id) {
            loadExistingData(true);
        }
    }, [id]);

    const clickCancel = () => {
        history.push(`/projectsandrequests`);
    }

    const postAirNetwork = () => {
        let airNetworkPostModel = Mapper.extractValuesFromModel(mainModel);

        API.put(anUrl + '/' + id, airNetworkPostModel).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            loadExistingData();
        })
    }

    const loadExistingData = (initialValue) => {
        API.get(anUrl + '/' + id).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.airNetworkAnalysis, AirNetworkModel(t));
            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.airtNetworkCalcResult, AirNetworkModel(t));
            setMainModel({ ...mappedModel, ...calcMappedModel });

            let distLines = Mapper.mergeArrayModels(resp.airNetworkAnalysis.distributionLineList, resp.airtNetworkCalcResult.distributionLineResults)
            enumerateTable(distLines);
            setDistLineList(distLines);
            setFilterDataDist(distLines);

            let airReceivers = resp.airNetworkAnalysis.airReceiverList;
            enumerateTable(airReceivers);
            setAirReceiverList(airReceivers);
            setFilterDataReceiver(airReceivers);

            let mappedPressureDrops = Mapper.mergeArrayModels(resp.airNetworkAnalysis.pressureDropAnalysisList, resp.airtNetworkCalcResult.pressureDropResults);
            for (let pressureDrop of mappedPressureDrops) {
                pressureDrop.pressureDropSavings = pressureDrop.reducingPressureDropCalcs[2]?.totalCosts;
                pressureDrop.pressureLevelSavings = pressureDrop.reducingPressureLevelCalcs[2]?.totalCosts;
            }
            setAirNetworkCalcs(resp.airtNetworkCalcResult);
            setPressureDropList(mappedPressureDrops);
            setFilterDataPDAnalysis(mappedPressureDrops);
            if (initialValue) ScrollerToTag();
        })
    }

    const enumerateTable = table => {
        table.forEach((line, index) => {
            line.no = index + 1;
        });
    }

    const deleteTableLinePost = tableName => {
        let [array, setArray] = getDeletionHooks(tableName);
        let delUrl = getDelUrl(tableName);

        let postModel = {
            ProjectId: id,
            IndexArray: array
        };

        API.post(delUrl, postModel).then(() => {
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
            setArray([]);
            loadExistingData();
        })
    }

    const getDelUrl = tableName => {
        switch (tableName) {
            case 'DistributionLine':
                return anUrl + `/${id}/distribution-line/`;
            case 'AirReceiver':
                return anUrl + `/${id}/air-receiver/`;
            case 'PressureDropAnalysis':
                return anUrl + `/${id}/pressure-drop-analysis/`;
            default:
                return;
        }
    }

    const clickDelete = tableName => {
        let [array] = getDeletionHooks(tableName);
        if (array == undefined || array.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal(tableName);
    }

    const clickDeleteOpenModal = tableName => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = () => deleteTableLinePost(tableName);
        setModalModel({ ...modalModel });
    }

    const isCheckedForDeletion = (tableName, id) => {
        let [array] = getDeletionHooks(tableName);
        return array.some(x => x == id);
    }

    const checkForDeletion = (tableName, id) => {
        let isAlreadyChecked = isCheckedForDeletion(tableName, id);
        let [array, setArray] = getDeletionHooks(tableName);
        let checkedArray = isAlreadyChecked ? array.filter(x => x != id) : [...array, id];
        setArray(checkedArray);
    }

    const getDeletionHooks = tableName => {
        let array;
        let setArray;
        switch (tableName) {
            case "DistributionLine":
                array = distLineListMarkedForDeletion;
                setArray = setDistLineListMarkedForDeletion;
                break;
            case "AirReceiver":
                array = airReceiverListMarkedForDeletion;
                setArray = setAirReceiverListMarkedForDeletion;
                break;
            case "PressureDropAnalysis":
                array = pressureDropListMarkedForDeletion;
                setArray = setPressureDropListMarkedForDeletion;
                break;
            default:
                return;
        }
        return [array, setArray];
    }

    const addTableLine = tableName => {
        pushHistoryForTableEdit(tableName, 0);
    }

    const pushHistoryForTableEdit = (tableName, cid) => history.push(getTableEditLink(tableName, cid));

    const getTableEditLink = (tableName, cid) => `/project/${id}/AN/${tableName}/${cid}#Tabs`;

    const saveForm = () => {
        let isValid = validateForm();
        if (isValid === true)
            postAirNetwork();
        else
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        return isValid;
    }

    const validateForm = () => {
        let isValid = true;
        Object.values(mainModel).forEach(field => field.validators.forEach(v => {
            if (v(field.value) === false) isValid = false;
        }));
        return isValid;
    }

    const updateModel = (property, value) => {
        let updatedModel = Mapper.updateModel(property, value, mainModel);
        setMainModel({ ...updatedModel });
        DirtyStore.setIsDirty(saveForm);
    }

    const filterFunctionDistLines = (tempdata) => {
        setDistLineList(tempdata);
    }

    const filterFunctionReceiver = (tempdata) => {
        setAirReceiverList(tempdata);
    }

    const filterFunctionPDAnalysis = (tempdata) => {
        setPressureDropList(tempdata);
    }

    const loadAvailableCompressorRooms = async () => {
        let resp = await API.get(`${anUrl}/${id}/pressure-drop-analysis/0/available-compressor-rooms`);
        return resp;
    }

    const addPressureDrop = async () => {
        try {

            let availableCompressors = await loadAvailableCompressorRooms();
            if (availableCompressors.length > 0)
                addTableLine("PressureDropAnalysis");
            else
                OpenGlobalSnackbar("danger", t('NoCompressorRoomsAreAvailableToBeAllocated'));

        } catch {
            OpenGlobalSnackbar("danger", t('NoCompressorRoomsAreAvailableToBeAllocated'));
        }
    }

    return (
        <div className="air-network table-layout-fix">
            <ModalComponent model={modalModel} />
            <Grid container spacing={5} direction="column">
                <Grid item id="#DistributionLine">
                    <HeadingComponent value={t('DistributionLine')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableHeadingContent
                        clickDelete={() => clickDelete("DistributionLine")}
                        clickAdd={() => addTableLine("DistributionLine")}
                        defaultData={distLineList}
                        filterData={filterDataDist}
                        onFilterFunction={filterFunctionDistLines}
                        filterModel={FilterModel.DistributionLine}
                        denyEdit={denyEdit}
                        tableId={"DistributionLine"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    {!denyEdit && <TableCell></TableCell>}
                                    <TableHeadCells
                                        model={distributionLineModel}
                                        propertyList={["no", "measurementId", "name", "building", "pipeDiameter", "distributionLineLayout", "pipeMaterial", "totalPipeLength", "pipeVolume", "comment"]}
                                        tableName="DistributionLine"
                                        data={distLineList}
                                        setData={setDistLineList}
                                    />
                                    <TableCell></TableCell>
                                </TableRow >
                            </TableHead >
                            {distLineList && distLineList.length > 0 ?
                                <TableBody>
                                    {distLineList.slice(distLineListCurrentPage * distLineListRowsPerPage, distLineListCurrentPage * distLineListRowsPerPage + distLineListRowsPerPage).map((item, index) =>
                                        <TableRow key={"DistributionLine" + index}>
                                            {!denyEdit &&
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={"DistributionLine-checkbox" + index}
                                                        key={Math.random()}
                                                        defaultChecked={isCheckedForDeletion("DistributionLine", item.id)}
                                                        color="default"
                                                        onChange={() => checkForDeletion("DistributionLine", item.id)}>
                                                    </SimpleCheckbox>
                                                </TableCell>
                                            }
                                            <TableCell id={"td_DistributionLine-no" + index}>{item.no}</TableCell>
                                            <TableCell id={"td_DistributionLine-measurementId" + index}>
                                                <LinkComponent
                                                    link={getTableEditLink("DistributionLine", item.id)}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_DistributionLine-name" + index}>{item.name}</TableCell>
                                            <TableCell id={"td_DistributionLine-building" + index}>{item.building}</TableCell>
                                            <TableCell id={"td_DistributionLine-pipeDiameter" + index}>{Fmt.round(item.pipeDiameter, country, 0)}</TableCell>
                                            <TableCell id={"td_DistributionLine-distributionLineLayout" + index}>{t(item.distributionLineLayout?.value)}</TableCell>
                                            <TableCell id={"td_DistributionLine-pipeMaterial" + index}>{item.pipeMaterial}</TableCell>
                                            <TableCell id={"td_DistributionLine-totalPipeLength" + index}>{Fmt.round(item.totalPipeLength, country, 2)}</TableCell>
                                            <TableCell id={"td_DistributionLine-pipeVolume" + index}>{Fmt.round(item.pipeVolume, country, 1)}</TableCell>
                                            <TableCell id={"td_DistributionLine-comment" + index}>{item.comment ? item.comment.slice(0, 10) : null}</TableCell>
                                            <TableCell >
                                                <IconButton
                                                    id={"btn_DistributionLine-edit" + index}
                                                    href={getTableEditLink("DistributionLine", item.id)}
                                                    onClick={() => pushHistoryForTableEdit("DistributionLine", item.id)}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        {!denyEdit && <TableCell></TableCell>}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className="font-bold-table">
                                            <TextFieldComponent
                                                disabled
                                                model={{ value: t("Total") }}
                                            />
                                        </TableCell>
                                        <TableCell id="td_DistributionLine-total-totalDistributionLinePipeLength" className="font-bold-table">
                                            <TextFieldComponent
                                                disabled
                                                model={{ value: Fmt.round(mainModel.totalDistributionLinePipeLength.value, country, 2) }}
                                            />
                                        </TableCell>
                                        <TableCell id="td_DistributionLine-total-totalDistributionLinePipeVolume" className="font-bold-table">
                                            <TextFieldComponent
                                                disabled
                                                model={{ value: Fmt.round(mainModel.totalDistributionLinePipeVolume.value, country, 1) }}
                                            />
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <div>{t('NoData')}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={distLineList && distLineList.length > 0 ? distLineList.length : 0}
                        rowsPerPage={distLineListRowsPerPage}
                        page={distLineListCurrentPage}
                        onPageChange={(e, page) => setDistLineListCurrentPage(page)}
                        onRowsPerPageChange={e => setDistLineListRowsPerPage(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} id="#AirReceivers">
                    <HeadingComponent value={t('AirReceivers')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableHeadingContent
                        clickDelete={() => clickDelete("AirReceiver")}
                        clickAdd={() => addTableLine("AirReceiver")}
                        defaultData={airReceiverList}
                        filterData={filterDataReceiver}
                        onFilterFunction={filterFunctionReceiver}
                        filterModel={FilterModel.AirReceivers}
                        denyEdit={denyEdit}
                        tableId={"AirReceiver"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    {!denyEdit && <TableCell></TableCell>}
                                    <TableHeadCells
                                        model={airReceiverModel}
                                        propertyList={["no", "measurementId", "building", "department", "machine", "volume", "workingPressure", "year", "lsInspection", "comment"]}
                                        tableName="AirReceiver"
                                        data={airReceiverList}
                                        setData={setAirReceiverList}
                                    />
                                    <TableCell></TableCell>
                                </TableRow >
                            </TableHead >
                            {airReceiverList && airReceiverList.length > 0 ?
                                <TableBody>
                                    {airReceiverList.slice(airReceiverListCurrentPage * airReceiverListRowsPerPage, airReceiverListCurrentPage * airReceiverListRowsPerPage + airReceiverListRowsPerPage).map((item, index) =>
                                        <TableRow key={"AirReceiver" + index}>
                                            {!denyEdit &&
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={"AirReceiver-checkbox" + index}
                                                        key={Math.random()}
                                                        defaultChecked={isCheckedForDeletion("AirReceiver", item.id)}
                                                        color="default"
                                                        onChange={() => checkForDeletion("AirReceiver", item.id)}>
                                                    </SimpleCheckbox>
                                                </TableCell>
                                            }
                                            <TableCell id={"td_AirReceiver-no" + index}>{item.no}</TableCell>
                                            <TableCell id={"td_AirReceiver-measurementId" + index}>
                                                <LinkComponent
                                                    link={getTableEditLink("AirReceiver", item.id)}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_AirReceiver-building" + index}>{item.building}</TableCell>
                                            <TableCell id={"td_AirReceiver-department" + index}>{item.department}</TableCell>
                                            <TableCell id={"td_AirReceiver-machine" + index}>{item.machine}</TableCell>
                                            <TableCell id={"td_AirReceiver-volume" + index}>{Fmt.round(item.volume, country, 2)}</TableCell>
                                            <TableCell id={"td_AirReceiver-workingPressure" + index}>{Fmt.round(item.workingPressure, country, 2)}</TableCell>
                                            <TableCell id={"td_AirReceiver-year" + index}>{item.year}</TableCell>
                                            <TableCell id={"td_AirReceiver-lsInspection" + index}>{DateHelper.FormatDate(item.lsInspection)}</TableCell>
                                            <TableCell id={"td_AirReceiver-comment" + index}>{item.comment ? item.comment.slice(0, 10) : null}</TableCell>
                                            <TableCell >
                                                <IconButton
                                                    id={"btn_AirReceiver-edit" + index}
                                                    href={getTableEditLink("AirReceiver", item.id)}
                                                    onClick={() => pushHistoryForTableEdit("AirReceiver", item.id)}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        {!denyEdit && <TableCell></TableCell>}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className="font-bold-table">
                                            <TextFieldComponent
                                                disabled
                                                model={{ value: t("Total") }}
                                            />
                                        </TableCell>
                                        <TableCell id="td_AirReceiver-total-totalAirReceiversVolume" className="font-bold-table">
                                            <TextFieldComponent
                                                disabled
                                                model={{ value: Fmt.round(mainModel.totalAirReceiversVolume.value, country, 2) }}
                                            />
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <div>{t('NoData')}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={airReceiverList && airReceiverList.length > 0 ? airReceiverList.length : 0}
                        rowsPerPage={airReceiverListRowsPerPage}
                        page={airReceiverListCurrentPage}
                        onPageChange={(e, page) => setAirReceiverListCurrentPage(page)}
                        onRowsPerPageChange={e => setAirReceiverListRowsPerPage(e.target.value)}
                    />
                </Grid>
                <Grid item id="#StorageAirCapacity">
                    <HeadingComponent value={t('StorageAirCapacity')} size="h6" />
                </Grid>
                <Grid item md={4}>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{t('TotalVolumeNetwork') + Fmt.getUnitInBracket("cubicMeter", t)}</TableCell>
                                    <TableCell id={"td_" + mainModel.totalVolumeNetwork.idkey} align="left">{Fmt.round(mainModel.totalVolumeNetwork.value, country, 1)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">{t('TotalVolumeAirReceivers') + Fmt.getUnitInBracket("cubicMeter", t)}</TableCell>
                                    <TableCell id={"td_" + mainModel.totalVolumeAirReceivers.idkey} align="left">{Fmt.round(mainModel.totalVolumeAirReceivers.value, country, 1)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" className="font-bold">{t('Total') + Fmt.getUnitInBracket("cubicMeter", t)}</TableCell>
                                    <TableCell id={"td_" + mainModel.total.idkey} align="left" className="font-bold">{Fmt.round(mainModel.total.value, country, 1)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item id="#PressureDropAnalysis">
                    <HeadingComponent value={t('PressureDropAnalysis')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableHeadingContent
                        clickDelete={() => clickDelete("PressureDropAnalysis")}
                        clickAdd={addPressureDrop}
                        defaultData={pressureDropList}
                        filterData={filterDataPDAnalysis}
                        onFilterFunction={filterFunctionPDAnalysis}
                        filterModel={FilterModel.PressureDropAnalysis}
                        denyEdit={denyEdit}
                        tableId={"PressureDropAnalysis"}
                    />
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} className="table-rightborder"></TableCell>
                                    <TableCell colSpan={3} align="center" className="table-rightborder">{t("PressureDrop")}</TableCell>
                                    <TableCell colSpan={3} align="center">{t("PressureLevel")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableHeadCells
                                        model={pressureDropModel}
                                        propertyList={["no", "measurementId", "nameOfTheAirNetwork", "pressureDropMax", "pressureDropTOTAL", "pressureDropSavings", "suggestedSetpointPressureRel", "pressureSetpointPressureRel", "pressureLevelSavings"]}
                                        styleList={[{}, {}, { className: "table-rightborder" }, {}, {}, { className: "table-rightborder" }, {}, {}, {}]}
                                        tableName={"PressureDropAnalysis"}
                                        data={pressureDropList}
                                        setData={setPressureDropList}
                                    />
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pressureDropList != undefined && pressureDropList.length > 0 &&
                                    pressureDropList.map((item, index) =>
                                        <TableRow id={'pressure-drops-row' + index} key={'pressure-drops' + index}>
                                            <TableCell>
                                                <SimpleCheckbox
                                                    disabled={denyEdit}
                                                    id={'pressure-drops-checkbox' + index}
                                                    defaultChecked={pressureDropListMarkedForDeletion.includes(item.id)}
                                                    key={Math.random()}
                                                    color="default"
                                                    onChange={e => e.target.checked ? setPressureDropListMarkedForDeletion([...pressureDropListMarkedForDeletion, item.id]) : setPressureDropListMarkedForDeletion(pressureDropListMarkedForDeletion.filter(x => x != item.id))}
                                                />
                                            </TableCell>
                                            <TableCell id={"pressure-drops-no" + index} align="left">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-MeasurementId" + index} align="left">
                                                <LinkComponent
                                                    link={getTableEditLink("PressureDropAnalysis", item.id)}
                                                    innerComponent={item.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"pressure-drops-NameOfTheAirNetwork" + index} align="left" className="table-rightborder">
                                                {item.nameOfTheAirNetwork}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-MaximalSuggestedDrop" + index} align="left">
                                                {Fmt.round(item.pressureDropMax, country, 1)}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-MeasuredPressureDrop" + index} align="left">
                                                {Fmt.round(item.pressureDropTOTAL, country, 1)}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-MeasuredPressureDrop" + index} align="left" className="table-rightborder">
                                                {Fmt.round(item.pressureDropSavings, country, 0)}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-SuggestedSetpointPressureRel" + index} align="left">
                                                {Fmt.round(item.suggestedSetpointPressureRel, country, 1)}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-SetpointPressureRel" + index} align="left">
                                                {Fmt.round(item.pressureSetpointPressureRel, country, 1)}
                                            </TableCell>
                                            <TableCell id={"pressure-drops-MeasuredPressureDrop" + index} align="left">
                                                {Fmt.round(item.pressureLevelSavings, country, 0)}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    href={getTableEditLink("PressureDropAnalysis", item.id)}
                                                    onClick={() => pushHistoryForTableEdit("PressureDropAnalysis", item.id)}
                                                    id={'pressure-drops-MeasuredPressureDrop-edit-button' + index}
                                                    size="large"
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {pressureDropList.length > 0 &&
                    <React.Fragment>
                        <Grid item>
                            <HeadingComponent value={t("Savings")} size="h6" />
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('SavingsReducingThePressureDropTable')} size="h6" />
                        </Grid>
                        {airNetworkCalcs.reducingPressureDropTotalCalcs?.length > 0 &&
                            <Grid item xs={12}>
                                <CostSavingsTable data={airNetworkCalcs.reducingPressureDropTotalCalcs} costSavingType="SavingsReducingThePressureDropTable" index={99} hidePressureLevel />
                            </Grid>
                        }
                        <Grid item>
                            <HeadingComponent value={t('SavingsReducingThePressureLevelOfTheSystemTable')} size="h6" />
                        </Grid>
                        {airNetworkCalcs.reducingPressureLevelTotalCalcs?.length > 0 &&
                            <Grid item xs={12}>
                                <CostSavingsTable data={airNetworkCalcs.reducingPressureLevelTotalCalcs} costSavingType="SavingsReducingThePressureLevelOfTheSystemTable" index={99} hidePressureLevel />
                            </Grid>
                        }
                    </React.Fragment>
                }
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        id="textarea_AN-comment"
                        type="text"
                        value={mainModel.comment.value}
                        minRows="5"
                        onChange={e => updateModel("comment", e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.AirNetworkAnalysis} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="AN-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="AN-save" value={t('Save')} color="primary" onChange={saveForm}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

