import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Typography, Link } from "@mui/material";
import { ButtonComponent } from '../Reusable';
import Grid from '@mui/material/Unstable_Grid2';
import "./Home.css";

interface IProps {
    handleSideBar: (show: boolean, iconHide?: boolean) => void,
}

export function Home(props: IProps) {
    const history = useHistory();
    const { t } = useTranslation();
    useEffect(() => {
        props.handleSideBar(false)
    }, [])

    const clickFesto = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        window.location.href = "https://www.festo.com/de/en/e/support/energy-saving-services-id_21636";
    };

    const clickRegister = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        window.location.href = "https://www.festo.com/register/?backToUri=" + encodeURIComponent(window.location.href);
    };

    const clickServiceRequest = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        history.push(`/contact`);
    };

    return (
        <Grid container className="content-max-height showcase" alignItems={"center"}>
            <Grid xs={12} sm={6} container>
                <Grid><img className="showcaseLogo" alt="#" src={"/pictures/FessMainPage.png"} style={{ position: 'relative', left: '-15vw', width: '60vw', height: '100%' }} /></Grid>
            </Grid>
            <Grid xs={12} sm={6} container className="content-max-height">
                <Grid xs={12} alignSelf="end" pb={8}>
                    <Grid container alignItems={"center"}>
                        <Grid>
                            <img className="logoIMG" src={"/pictures/EnergySavingServices_final.svg"} alt="#" width={36} height={36} />
                        </Grid>
                        <Grid>
                            <Typography className="text-fess" textAlign={'start'}>
                                {t("HomePageIconText")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Typography className="text-fess-subheadline" id="contact-headline" minHeight={24} textAlign={'left'}>
                            {t("HomePageFessHeadline")}
                        </Typography>
                        <Typography className="text-fess-medium" id="contact-content" maxWidth={500} textAlign={'left'}>
                            {t("HomePageContactContent")}
                        </Typography>
                    </Grid>
                    <Grid container >
                        <ButtonComponent
                            id="lnk_ServiceRequest"
                            value={t("HomePageContactButton")}
                            color="primary"
                            customStyle={{ margin: 0 }}
                            onChange={e => clickServiceRequest(e)}
                        />
                    </Grid>
                </Grid>
                <Grid container alignSelf="start" xs={6} maxWidth={350} direction={'column'} pr={1}>
                    <Typography className="text-fess-headline" id="registration-headline" textAlign={'left'}>
                        {t("HomePageRegistrationTitle")}
                    </Typography>
                    <Typography className="text-fess-medium" id="registration-content" maxWidth={600} minHeight={100} textAlign={'left'}>
                        {t("HomePageRegistrationContent")}
                    </Typography>
                    <Link id="lnk_Register" onClick={e => clickRegister(e)} underline="hover" maxHeight={24}>
                        <Typography textAlign={'left'} id="registration-link">
                            {t('HomePageRegistrationButton')}
                        </Typography>
                    </Link>
                </Grid>
                <Grid container alignSelf="start" xs={6} maxWidth={350} direction={'column'}>
                    <Typography className="text-fess-headline" id="fess-headline" textAlign={'left'}>
                        {t("HomePageFestoTitle")}
                    </Typography>
                    <Typography className="text-fess-medium" id="fess-content" maxWidth={600} minHeight={100} textAlign={'left'}>
                        {t("HomePageFestoContent")}
                    </Typography>
                    <Link id="lnk_Festo" onClick={e => clickFesto(e)} underline="hover" maxHeight={24}>
                        <Typography textAlign={'left'} id="festo-link">
                            {t('HomePageFestoButton')}
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}
