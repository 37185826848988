import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExecutorStore, UserStore } from '../../store';
import * as Icon from '@mui/icons-material/';
import QrCodeSearch from './QrCodeSearch';
import QrCodeGeneration from './QrCodeGeneration';
import ExecutorInfoView from './ExecutorInfoView/ExecutorInfoView';

export default function LandingPageView() {
    const { t } = useTranslation();
    let history = useHistory();

    const [executorSettings, setExecutorSettings] = useState({});
    const [user, setUser] = useState({})

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(exec => setExecutorSettings(exec));
        let userSub = UserStore.user.subscribe(user => setUser(user));
        return () => {
            execSub.unsubscribe();
            userSub.unsubscribe();
        }
    }, []);

    const setDefaultExecutor = () => {
        ExecutorStore.setDefaultExecutor(executorSettings.id);
        executorSettings.isDefaultExecutor = true;
        setExecutorSettings({ ...executorSettings })
    }

    return (
        <div>
            <Grid container justifyContent="center" alignItems="center" direction="row" >
                <Grid item md={8}>
                    <Grid container justifyContent="center" alignItems="center" direction="column" >
                        <ExecutorInfoView />
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Grid container justifyContent="center" spacing={3} alignItems="center" direction="column" >
                        <Grid item md={12} style={{ marginTop: "100px" }} className="projectMenu-secondtext">
                            {executorSettings?.isDefaultExecutor === false &&
                                <Grid item>
                                    <Button
                                        onClick={setDefaultExecutor}
                                        id="btn_executor-set-default-executor"
                                        className="projectMenu-button"
                                    >
                                        <Icon.AssignmentInd />
                                        {t("SetDefaultExecutor")}
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    onClick={() => history.push(`/SettingsPanel/ExecutorManagement`)}
                                    id="btn_executor_mngmnt"
                                    className="projectMenu-button"
                                >
                                    <Icon.Business />
                                    {t("Settings")}
                                </Button>
                            </Grid>
                            {Object.keys(user).length > 0 && (user.userRights.isSuperAdmin
                                || user.userRights.isExecutorAdmin
                                || user.userRights.isTechnician
                                || user.userRights.isEditor) ?
                                <React.Fragment>
                                    <Grid item className="projectMenu-secondtext">
                                        <QrCodeGeneration executorSettings={executorSettings} showPrintExistingCodes={false}/>
                                    </Grid>
                                    <Grid item className="projectMenu-secondtext">
                                        <QrCodeSearch />
                                    </Grid>
                                </React.Fragment>
                                : null}


                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ paddingTop: "42px" }}></div>
        </div>
    )
}
