import React, { useState } from 'react'
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { LinkComponent } from '../Reusable';
import "./SideWrapper.css";

export interface SideWrapperChildProps {
    handleSideBar: (show: boolean, iconHide?: boolean) => void;
    showContent?: boolean;
    show: boolean;
}
interface IProps {
    children: ((props: SideWrapperChildProps) => React.ReactNode)
}

export default function SideWrapper(props: IProps) {
    const { t } = useTranslation();

    const [contentNav, setContentNav] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [hideIcon, setHideIcon] = useState(false);

    const handleSideBar = (show: boolean, iconHide?: boolean) => {
        setSideBar(show);
        setHideIcon(!iconHide);
    }

    return (
        <div>
            {sideBar ?
                <div className="sideBar">
                    <Grid item className="sideBar-left">
                        <Grid container justifyContent="center">
                            <LinkComponent
                                id="nav-logo-side"
                                link={`/`}
                                innerComponent={<img
                                    className="logoIMG"
                                    src={"/pictures/EnergySavingServices_final.svg"}
                                    alt="#"
                                />}
                            />
                            {hideIcon ? "" : <Tooltip title={contentNav ? t("ShowContentNavigation") : t("HideContentNavigation")}><IconButton
                                className={contentNav ? "" : "Content-nav-rotate"}
                                onClick={() => setContentNav(!contentNav)}
                                size="large"><DoubleArrowIcon /></IconButton></Tooltip>}
                        </Grid>
                    </Grid>
                    <Grid item className="sideBar-right">
                        {props.children({
                            showContent: contentNav,
                            handleSideBar: handleSideBar,
                            show: sideBar,
                        })}
                    </Grid>
                </div>
                : <div>
                    {props.children({
                        handleSideBar: handleSideBar,
                        show: sideBar,
                    })}
            </div>}
        </div>
    );
}
