import React, { useState, useEffect } from 'react';
import * as Fmt from '../../helper/Formatters';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Grid, Button } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { HeadingComponent } from '../Reusable';
import { LeakageModelFactory, ILeakageModelFactory } from '../LeakageDetectionAndRepair/Leakage/LeakageModel';
import { IDropdownItem, ILeakage, IProjectLDARResults, IReplacementPart, IdParam, ModelFactory } from '../Types/Types';
import { LeakageActions, LeakageInputDevices, LeakageRepairTimeEstimations, OpenGlobalSnackbar, ReplacementPartComponents, ScrollerToTag } from '../../helper/GlobalVariables';
import { useGridApiRef, GridColDef, GridRowsProp, GridValidRowModel, GridActionsCellItem, GridToolbarContainer, DataGridPro } from '@mui/x-data-grid-pro';
import { datesColDef, itemsColDef, inputColDef, valueColDef, yesNoColDef, sxStyle, getRowClassName, ChangesRef, discardChanges, processRowUpdate } from './MassEditHelper';
import { getLocale, TranslationLanguages } from '../../helper/CountryLanguages';
import { IReplacementFactory, ReplacementPartModel } from '../ReplacementParts/ReplacementPartModel';
import { csvColumn, csvLeakage, csvReplacement, rsiTheme, rsiTranslations } from './CsvHelper';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import ImportIcon from '@mui/icons-material/ImportExport';
import RestoreIcon from '@mui/icons-material/Restore';
import BackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import MiniSearch from 'minisearch';
import { v4 as uuidv4 } from 'uuid';
import "./MassEdit.css";
import _ from 'lodash';
import { RawData } from 'react-spreadsheet-import/types/types';

export default function MassEditLDAR(props: { denyEdit: boolean }) {
    const { t } = useTranslation();
    const ldUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-detection";
    const { id } = useParams<IdParam>();
    const { country, lng } = getLocale();
    const [isSaving, setIsSaving] = React.useState(false);

    const leakageApiRef = useGridApiRef();
    const leakageModel = LeakageModelFactory(t);
    const [leakageData, setLeakageData] = useState<(ILeakage)[]>([]);
    const [hasLeakageUnsavedRows, setHasLeakageChanges] = React.useState(false);
    const leakageChangesRef = React.useRef<ChangesRef>({ unsavedRows: {}, rowsBeforeChange: {} });
    const upsertLeakagesUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-upsert-leakages";

    const partApiRef = useGridApiRef();
    const partModel = ReplacementPartModel(t);
    const [partData, setPartData] = useState<(ILeakage)[]>([]);
    const [hasPartUnsavedRows, setHasPartChanges] = React.useState(false);
    const partChangesRef = React.useRef<ChangesRef>({ unsavedRows: {}, rowsBeforeChange: {} });
    const upsertPartUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-upsert-replacements";

    useEffect(() => {
        if (parseInt(id) > 0) {
            fetchData(true);
        }
    }, []);

    const fetchData = (initialValue?: boolean) => {
        try {
            API.get<IProjectLDARResults>(ldUrl + '/' + id).then(resp => {
                if (resp.ldar.leakages !== null) {
                    const leakagesWithCalcs = resp.ldar.leakages.map((leakage: ILeakage, index: number) => ({ ...leakage, leakageFlowConverted: resp.ldarCalcResults.indLeakageCalcResults[index].leakageFlowConverted }));
                    setLeakageData(leakagesWithCalcs);
                    setPartData(leakagesWithCalcs);
                }
                if (initialValue) ScrollerToTag();
            })
        } catch {
            OpenGlobalSnackbar("danger", t('error'));
        }
    };

    const leakageRows: GridRowsProp<ILeakage> = React.useMemo(() => leakageData.filter(x => x.id > 0).map((item) => ({ gridId: uuidv4(), ...item })), [leakageData]);

    const partRows: GridRowsProp<IReplacementPart & { leackageNumber: { id: number, value: number | undefined }, leakageParent: any }> = React.useMemo(() =>
        partData.filter(x => x.id > 0).flatMap((item) => {
            const partRows = item.replacementPartList.map((replacementPart) => ({
                gridId: uuidv4(),
                ...replacementPart,
                leakageParent: item.measurementId,
                leackageNumber: { id: item.id, value: item.leackageNumber },
            }));
            return partRows;
        }),
        [partData]
    );

    const leakageColumns = React.useMemo<GridColDef[]>(() => [
        {
            type: 'actions',
            field: 'Actions',
            headerClassName: 'datagrid-bold',
            headerName: t('Actions') ?? 'Actions',
            getActions: ({ id, row, columns }) => [
                <GridActionsCellItem
                    key={'RestoreGridCell'}
                    icon={<RestoreIcon />}
                    label="Discard changes"
                    disabled={leakageChangesRef.current.unsavedRows[id] === undefined || leakageChangesRef.current.unsavedRows[id]?.id === 0}
                    onClick={() => {
                        leakageApiRef.current.updateRows([leakageChangesRef.current.rowsBeforeChange[id]]);
                        delete leakageChangesRef.current.rowsBeforeChange[id];
                        delete leakageChangesRef.current.unsavedRows[id];
                        columns.forEach((item) => {
                            if (leakageApiRef.current.getCellMode(id, item.field) === 'edit') {
                                leakageApiRef.current.stopCellEditMode({ id: id, field: item.field, ignoreModifications: true });
                            }
                        });
                        setHasLeakageChanges(Object.keys(leakageChangesRef.current.unsavedRows).length > 0);
                    }}
                />,
                <GridActionsCellItem
                    key={'DeleteGridCell'}
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() => {
                        if (leakageChangesRef.current.unsavedRows[id]?.id === 0) {
                            leakageChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            leakageApiRef.current.updateRows([leakageChangesRef.current.unsavedRows[id]]);
                        }
                        else {
                            leakageChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            if (!leakageChangesRef.current.rowsBeforeChange[id]) { leakageChangesRef.current.rowsBeforeChange[id] = row; }
                            setHasLeakageChanges(true);
                            leakageApiRef.current.updateRows([row]);
                        }
                    }}
                />,
            ],
        },
        valueColDef(leakageModel, t, "leackageNumber", { minWidth: 20 }),
        valueColDef(leakageModel, t, "measurementId"),
        datesColDef(leakageModel, t, "detectedDate"),
        inputColDef(leakageModel, t, "detectedBy"),

        inputColDef(leakageModel, t, "building"),
        inputColDef(leakageModel, t, "department"),
        inputColDef(leakageModel, t, "machine"),

        itemsColDef(leakageModel, t, "leakageInputDevice", LeakageInputDevices),
        inputColDef(leakageModel, t, "leakageInputValue"),
        valueColDef(leakageModel, t, "leakageFlowConverted", { valueFormatter: (value: number) => Fmt.round(value, country, 2) }),
        itemsColDef(leakageModel, t, "leakageAction", LeakageActions),
        itemsColDef(leakageModel, t, "leakageRepairTimeEstimated", LeakageRepairTimeEstimations),
        yesNoColDef(leakageModel, t, "leakageOptimization"),
        yesNoColDef(leakageModel, t, "leakageRepearableProdTime"),

        yesNoColDef(leakageModel, t, "leakageRepaired"),
        inputColDef(leakageModel, t, "repairedBy"),
        datesColDef(leakageModel, t, "repairDate"),
        inputColDef(leakageModel, t, "leakageRepairTimeRepairedInput"),
        inputColDef(leakageModel, t, "leakageComment")
    ], [leakageChangesRef, leakageApiRef, lng]);

    const partColumns = React.useMemo<GridColDef[]>(() => [
        {
            type: 'actions',
            field: 'Actions',
            headerClassName: 'datagrid-bold',
            headerName: t('Actions') ?? 'Actions',
            getActions: ({ id, row, columns }) => [
                <GridActionsCellItem
                    key={'RestoreGridCell'}
                    icon={<RestoreIcon />}
                    label="Discard changes"
                    disabled={partChangesRef.current.unsavedRows[id] === undefined || partChangesRef.current.unsavedRows[id]?.id === 0}
                    onClick={() => {
                        partApiRef.current.updateRows([partChangesRef.current.rowsBeforeChange[id]]);
                        delete partChangesRef.current.rowsBeforeChange[id];
                        delete partChangesRef.current.unsavedRows[id];
                        columns.forEach((item) => {
                            if (partApiRef.current.getCellMode(id, item.field) === 'edit') {
                                partApiRef.current.stopCellEditMode({ id: id, field: item.field, ignoreModifications: true });
                            }
                        });
                        setHasPartChanges(Object.keys(partChangesRef.current.unsavedRows).length > 0);
                    }}
                />,
                <GridActionsCellItem
                    key={'DeleteGridCell'}
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() => {
                        if (partChangesRef.current.unsavedRows[id]?.id === 0) {
                            partChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            partApiRef.current.updateRows([partChangesRef.current.unsavedRows[id]]);
                        }
                        else {
                            partChangesRef.current.unsavedRows[id] = { ...row, _action: 'delete' };
                            if (!partChangesRef.current.rowsBeforeChange[id]) { partChangesRef.current.rowsBeforeChange[id] = row; }
                            setHasPartChanges(true);
                            partApiRef.current.updateRows([row]);
                        }
                    }}
                />,
            ],
        },
        {
            minWidth: 130,
            maxWidth: 250,
            field: 'leackageNumber',
            headerName: leakageModel.leackageNumber.label,
            valueOptions: leakageRows.map((item) => ({
                value: item.id,
                label: item.leackageNumber,
            })),
            valueGetter: (value: IDropdownItem) => value?.id,
            valueSetter: (value: number, row: object) => {
                const refRow = leakageRows.find(x => x.id == value);
                return {
                    ...row,
                    leackageNumber: { id: refRow?.id ?? 0, value: refRow?.leackageNumber ?? "" },
                    leakageParent: refRow?.measurementId
                }
            },
            getOptionValue: (value: any) => value?.value,
            getOptionLabel: (value: any) => value?.label,
            renderCell: (params) => {
                const isEmpty = !params.value;
                return (
                    <div style={{ color: isEmpty ? 'red' : 'inherit' }}>
                        {isEmpty ? t("SelectOneToSave") : leakageRows.find((row) => row.id === params.value)?.leackageNumber || ''}
                    </div>
                );
            },
            headerClassName: 'datagrid-bold',
            type: 'singleSelect',
            editable: true,
        },
        {
            minWidth: 100,
            maxWidth: 300,
            field: 'leakageParent',
            headerName: t("LeakageParent"),
            headerClassName: 'datagrid-bold',
            editable: false,
        },
        valueColDef(partModel, t, "id", { minWidth: 20 }),
        inputColDef(partModel, t, "partNumber"),
        inputColDef(partModel, t, "typeCode"),
        itemsColDef(partModel, t, "component", ReplacementPartComponents.sort((a, b) => a.id - b.id)),
        inputColDef(partModel, t, "manufacturer"),
        inputColDef(partModel, t, "newPartNumber"),
        inputColDef(partModel, t, "newTypeCode"),
        inputColDef(partModel, t, "newManufacturer"),
        inputColDef(partModel, t, "quantity"),
        yesNoColDef(partModel, t, "orderSparePart"),
    ], [partChangesRef, partApiRef, leakageRows, lng]);

    const miniSearchOptions = { fields: ['value'], storeFields: ['id', 'value'], searchOptions: { fuzzy: 0.6 } };
    const leakageActionSearch = new MiniSearch(miniSearchOptions);
    const leakageInputDeviceSearch = new MiniSearch(miniSearchOptions);
    const leakageRepairTimeEstimatedSearch = new MiniSearch(miniSearchOptions);
    const replacementPartComponentSearch = new MiniSearch(miniSearchOptions);

    leakageActionSearch.addAll(LeakageActions.map(x => ({ id: x.id, value: t(x.value) })));
    leakageInputDeviceSearch.addAll(LeakageInputDevices.map(x => ({ id: x.id, value: t(x.value) })));
    leakageRepairTimeEstimatedSearch.addAll(LeakageRepairTimeEstimations.map(x => ({ id: x.id, value: t(x.value) })));
    replacementPartComponentSearch.addAll(ReplacementPartComponents.map(x => ({ id: x.id, value: t(x.value) })));

    const addLeakage = (csv?: csvLeakage) => {
        setHasLeakageChanges(true);
        const leackageNumberBefore = Math.max(...leakageApiRef.current.getSortedRows().map(x => x.leackageNumber));
        const leackageNumber = leackageNumberBefore > 0 ? leackageNumberBefore + 1 : 1;
        const newDetectedDate = new Date(csv?.detectedDate ?? "");
        const newRepairDate = new Date(csv?.repairDate ?? "");
        const row = {
            id: 0,
            gridId: uuidv4(),
            leackageNumber: leackageNumber,
            detectedDate: newDetectedDate,
            detectedBy: csv?.detectedBy ?? "",
            measurementId: null,
            leakageRepaired: csv?.leakageRepaired ?? false,
            repairedBy: (csv !== undefined && csv?.repairedBy && csv.repairedBy.length > 0) ? csv.repairedBy : "",
            repairComment: csv?.repairComment ?? "",
            repairDate: newRepairDate,
            leakageRepairTimeRepairedInput: csv?.leakageRepairTimeRepairedInput ?? null,
            building: csv?.building ?? "",
            department: csv?.department ?? "",
            machine: csv?.machine ?? "",
            leakageInputValue: csv?.leakageInputValue ?? 0,
            leakageFlowConverted: csv?.leakageFlowConverted ?? 0,
            leakageRepearableProdTime: csv?.leakageRepearableProdTime ?? false,
            leakageOptimization: csv?.leakageOptimization ?? false,
            leakageComment: csv?.leakageComment ?? "",
            leakageAction: (csv !== undefined && csv.leakageAction && csv.leakageAction.length > 0) ? leakageActionSearch.search(csv.leakageAction)[0] : "",
            leakageInputDevice: (csv !== undefined && csv.leakageInputDevice && csv.leakageInputDevice.length > 0) ? leakageInputDeviceSearch.search(csv.leakageInputDevice)[0] : "",
            leakageRepairTimeEstimated: (csv !== undefined && csv.leakageRepairTimeEstimated && csv.leakageRepairTimeEstimated.length > 0) ? LeakageRepairTimeEstimations.find(x => x.id == leakageRepairTimeEstimatedSearch.search(csv.leakageRepairTimeEstimated ?? "")[0].id) : "",
        }
        leakageChangesRef.current.unsavedRows[row.gridId] = row;
        leakageApiRef.current.updateRows([row]);
    };

    const updateLeakage = (csv: csvLeakage, existingLeakage: GridValidRowModel) => {
        const gridId = existingLeakage.gridId;
        if (gridId) {
            const updatedLeakage = _.cloneDeep(existingLeakage);
            updatedLeakage.detectedBy = csv?.detectedBy ?? updatedLeakage.detectedBy;
            updatedLeakage.leakageRepaired = csv?.leakageRepaired ?? updatedLeakage.leakageRepaired;
            updatedLeakage.repairedBy = csv?.repairedBy ? csv.repairedBy : updatedLeakage.repairedBy;
            updatedLeakage.repairComment = csv?.repairComment ?? updatedLeakage.repairComment;
            updatedLeakage.building = csv?.building ?? updatedLeakage.building;
            updatedLeakage.department = csv?.department ?? updatedLeakage.department;
            updatedLeakage.machine = csv?.machine ?? updatedLeakage.machine;
            updatedLeakage.leakageRepearableProdTime = csv?.leakageRepearableProdTime ?? updatedLeakage.leakageRepearableProdTime;
            updatedLeakage.leakageOptimization = csv?.leakageOptimization ?? updatedLeakage.leakageOptimization;
            updatedLeakage.leakageComment = csv?.leakageComment ?? updatedLeakage.leakageComment;

            const newDetectedDate = new Date(csv?.detectedDate ?? "");
            const detectedDate = new Date(updatedLeakage.detectedDate);
            const newRepairDate = new Date(csv?.repairDate ?? "");
            const repairDate = new Date(updatedLeakage.repairDate);

            if ((newDetectedDate.getFullYear != detectedDate.getFullYear) && (newDetectedDate.getMonth != detectedDate.getMonth) && (newDetectedDate.getMonth != detectedDate.getMonth)) {
                updatedLeakage.detectedDate = newDetectedDate;
            }
            if ((newRepairDate.getFullYear != repairDate.getFullYear) && (newRepairDate.getMonth != repairDate.getMonth) && (newRepairDate.getMonth != repairDate.getMonth)) {
                updatedLeakage.repairDate = newRepairDate;
            }
            if (Number(csv?.leakageInputValue) != Number(updatedLeakage.leakageInputValue)) {
                updatedLeakage.leakageInputValue = csv?.leakageInputValue ?? updatedLeakage.leakageInputValue;
            }

            updatedLeakage.leakageAction = (csv !== undefined && csv.leakageAction && csv.leakageAction.length > 0) ? LeakageActions.find(x => x.id == leakageActionSearch.search(csv.leakageAction ?? "")[0].id) : updatedLeakage.leakageAction;
            updatedLeakage.leakageInputDevice = (csv !== undefined && csv.leakageInputDevice && csv.leakageInputDevice.length > 0) ? LeakageInputDevices.find(x => x.id == leakageInputDeviceSearch.search(csv.leakageInputDevice ?? "")[0].id) : updatedLeakage.leakageInputDevice;
            updatedLeakage.leakageRepairTimeEstimated = (csv !== undefined && csv.leakageRepairTimeEstimated && csv.leakageRepairTimeEstimated.length > 0) ? LeakageRepairTimeEstimations.find(x => x.id == leakageRepairTimeEstimatedSearch.search(csv.leakageRepairTimeEstimated ?? "")[0].id) : updatedLeakage.leakageRepairTimeEstimated;

            if (!_.isEqual(updatedLeakage, existingLeakage)) {
                leakageChangesRef.current.unsavedRows[gridId] = updatedLeakage;
                if (!leakageChangesRef.current.rowsBeforeChange[gridId] && !(existingLeakage.id === 0)) {
                    leakageChangesRef.current.rowsBeforeChange[gridId] = existingLeakage;
                }
                leakageApiRef.current.updateRows([updatedLeakage])
                setHasLeakageChanges(true);
            }
        }
    };

    const addPart = (csv?: csvReplacement) => {
        if (csv == null || (csv?.typeCode || csv?.partNumber || csv?.manufacturer || csv?.newTypeCode || csv?.newPartNumber || csv?.quantity || csv?.orderSparePart || csv.component)) {
            setHasPartChanges(true);
            const row = {
                id: 0,
                gridId: uuidv4(),
                typeCode: csv?.typeCode ?? "",
                partNumber: csv?.partNumber ?? "",
                manufacturer: csv?.manufacturer ?? "",
                newTypeCode: csv?.newTypeCode ?? "",
                newPartNumber: csv?.newPartNumber ?? "",
                newManufacturer: csv?.newManufacturer ?? "",
                quantity: csv?.quantity ?? 0,
                orderSparePart: csv?.orderSparePart ?? true,
                component: (csv !== undefined && csv.component && csv.component.length > 0) ? leakageInputDeviceSearch.search(csv.component)[0] : "",
            }
            partChangesRef.current.unsavedRows[row.gridId] = row;
            partApiRef.current.updateRows([row]);
        }
    };

    const updatePart = (csv: csvReplacement, existingPart: GridValidRowModel) => {
        const gridId = existingPart.gridId;
        const updatedPart = _.cloneDeep(existingPart);
        const oldPart = _.cloneDeep(existingPart);

        updatedPart.typeCode = csv?.typeCode ?? updatedPart.typeCode;
        updatedPart.partNumber = csv?.partNumber ?? updatedPart.partNumber;
        updatedPart.manufacturer = csv?.manufacturer ?? updatedPart.manufacturer;

        updatedPart.newTypeCode = csv?.newTypeCode ?? updatedPart.newTypeCode;
        updatedPart.newPartNumber = csv?.newPartNumber ?? updatedPart.newPartNumber;
        updatedPart.newManufacturer = csv?.newManufacturer ?? updatedPart.newManufacturer;

        if (!Number.isNaN(Number(csv?.quantity))) {
            updatedPart.quantity = Number(csv?.quantity) ?? updatedPart.quantity;
        }
        updatedPart.orderSparePart = csv?.orderSparePart ?? updatedPart.orderSparePart;
        updatedPart.component = (csv !== undefined && csv.component && csv.component.length > 0) ? ReplacementPartComponents.find(x => x.id == replacementPartComponentSearch.search(csv.component ?? "")[0].id) : updatedPart.component

        if (!_.isEqual(updatedPart, oldPart)) {
            partChangesRef.current.unsavedRows[gridId] = updatedPart;
            if (!partChangesRef.current.rowsBeforeChange[gridId] && !(oldPart.id === 0)) {
                partChangesRef.current.rowsBeforeChange[gridId] = oldPart;
            }
            partApiRef.current.updateRows([updatedPart])
            setHasPartChanges(true);
        }
    };

    const saveChanges = async (
        upsertUrl: string,
        changesRef: React.MutableRefObject<ChangesRef>,
        setHasChanges: React.Dispatch<React.SetStateAction<boolean>>,
        setData: React.Dispatch<React.SetStateAction<ILeakage[]>>
    ) => {
        try {
            setIsSaving(true);
            await API.put<IProjectLDARResults>(upsertUrl + '/' + id, {
                ToAdd: Object.values(changesRef.current.unsavedRows).filter((row) => row.id === 0),
                ToDelete: Object.values(changesRef.current.unsavedRows).filter((row) => row._action === 'delete'),
                ToUpdate: Object.values(changesRef.current.unsavedRows).filter((row) => row.id !== 0 && row._action !== 'delete'),
            }).then(resp => {
                setData(resp.ldar.leakages);
                OpenGlobalSnackbar("success", t('SaveSuccesfull'))
            })
            setIsSaving(false);
            changesRef.current = { unsavedRows: {}, rowsBeforeChange: {} };
            setHasChanges(false);
        }
        catch {
            setIsSaving(false);
        }
    };

    const suggestedMappingsUsed: Set<string> = new Set();
    const [isOpen, setIsOpen] = useState(false);
    const suggestedMappings: string[] = [];

    const addTranslations = (translationKey: string) => {
        TranslationLanguages.split('|').forEach((lng) => {
            const translatedString = t(translationKey, { lng });
            if (translatedString && !suggestedMappingsUsed.has(translatedString.toLowerCase())) {
                suggestedMappings.push(translatedString);
                suggestedMappingsUsed.add(translatedString.toLowerCase());
            }
        });
    };

    const csvLeakageColumnDef = Object.keys(leakageModel).reduce((columns: csvColumn, key, index) => {
        if (index === 0) {
            suggestedMappingsUsed.clear();
        }
        if (!["id", "guid", "leakageFlowConverted"].includes(key)) {
            const item = leakageModel[key as keyof ModelFactory<ILeakageModelFactory>];
            if (item.headerName) addTranslations(item.headerName);
            if (item.label) addTranslations(item.label);
            if (key && !suggestedMappingsUsed.has(key.toLowerCase())) {
                suggestedMappings.push(key);
                suggestedMappingsUsed.add(key.toLowerCase());
            }
            columns.push({
                key,
                label: item.label ?? index.toString(),
                alternateMatches: [...suggestedMappings],
                fieldType: { type: typeof (item.value) === "boolean" ? 'checkbox' : 'input' }
            });
            suggestedMappings.length = 0;
        }
        return columns;
    }, []);

    const csvPartColumnDef = Object.keys(partModel).reduce((columns: csvColumn, key, index) => {
        if (index === 0) {
            suggestedMappingsUsed.clear();
        }
        if (!["guid", "no"].includes(key)) {
            const item = partModel[key as keyof ModelFactory<IReplacementFactory>];
            if (item?.headerName) addTranslations(item.headerName);
            if (item?.label) addTranslations(item.label);
            if (key && !suggestedMappingsUsed.has(key.toLowerCase())) {
                suggestedMappings.push(key);
                suggestedMappingsUsed.add(key.toLowerCase());
            }
            columns.push({
                key,
                label: item?.label ?? index.toString(),
                alternateMatches: [...suggestedMappings],
                fieldType: { type: typeof (item?.value) === "boolean" ? 'checkbox' : 'input' }
            });
            suggestedMappings.length = 0;
        }
        return columns;
    }, []);


    return (
        <div style={{ width: '100%', height: 1100 }}>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                customTheme={rsiTheme}
                isNavigationEnabled={true}
                onClose={() => setIsOpen(false)}
                translations={rsiTranslations(t)}
                fields={csvLeakageColumnDef.concat(csvPartColumnDef)}
                selectHeaderStepHook={(headerValues: RawData, data: RawData[]) => {
                    return new Promise((resolve, reject) => {
                        const filteredHeaders = headerValues;
                        const filteredData = data;
                        [
                            t("LeakageFlowConverted") + Fmt.getUnitInBracket("volumeFlowRate", t),
                            t("VolumePerYear") + Fmt.getUnitInBracket("cubicMeterOfGas", t),
                            t("EnergyConsumptionPerYear") + Fmt.getUnitInBracket("powerPerHour", t),
                            t("CO2EmissionsPerYear") + Fmt.getUnitInBracket("co2Wheight", t),
                            t("CostPerYear") + Fmt.getCurrency(),
                        ]
                            .forEach((property) => {
                                const i = filteredHeaders.findIndex((header) => header === property);
                                if (i !== -1) {
                                    filteredHeaders.splice(i, 1);
                                    filteredData.forEach((row) => row.splice(i, 1));
                                }
                            })
                        try {
                            resolve({ headerValues: filteredHeaders, data: filteredData });
                        } catch (error) {
                            reject(error);
                        }
                    });
                }}
                onSubmit={(data) => {
                    setIsOpen(false);
                    const allPartRows = partApiRef.current.getSortedRows();
                    const allLeakageRows = leakageApiRef.current.getSortedRows();
                    const uniqueLeakages = data.validData.reduce<{ seen: Set<string>; uniqueRows: csvLeakage[] }>((accumulator, current) => {
                        const csvLeakage = current as unknown as csvLeakage;
                        const csvPart = current as unknown as csvReplacement;
                        if (!csvLeakage.measurementId) {
                            if (!csvLeakage.leackageNumber) {
                                addLeakage(csvLeakage);
                            }
                            else if (!accumulator.seen.has(`no_${csvLeakage.leackageNumber}`)) {
                                accumulator.seen.add(`no_${csvLeakage.leackageNumber}`);
                                accumulator.uniqueRows.push(csvLeakage);
                            }
                        } else if (!accumulator.seen.has(`${csvLeakage.measurementId}`)) {
                            accumulator.seen.add(`${csvLeakage.measurementId}`);
                            accumulator.uniqueRows.push(csvLeakage);
                        }
                        const existingPart = allPartRows.find(x => x.id == csvPart.id);
                        if (existingPart) {
                            updatePart(csvPart, existingPart);
                        } else {
                            addPart(csvPart);
                        }
                        return accumulator;
                    }, { seen: new Set(), uniqueRows: [] }).uniqueRows;

                    uniqueLeakages.forEach(csvLeakage => {
                        const existingLeakage = allLeakageRows.find(x => x.measurementId == csvLeakage.measurementId);
                        if (existingLeakage) {
                            updateLeakage(csvLeakage, existingLeakage);
                        } else {
                            addLeakage(csvLeakage);
                        }
                    });
                }}
            />
            <Grid item xs={12} id="#LeakageDetection">
                <HeadingComponent value={t('ListOfLeakages')} size="h6" />
            </Grid>
            <div style={{ height: 500 }}>
                <DataGridPro
                    density='compact'
                    loading={isSaving}
                    rows={leakageRows}
                    apiRef={leakageApiRef}
                    columns={leakageColumns}
                    autosizeOnMount={true}
                    isCellEditable={() => !props.denyEdit}
                    autosizeOptions={{ includeHeaders: true }}
                    getRowId={(row: GridValidRowModel) => row?.gridId}
                    processRowUpdate={React.useCallback(processRowUpdate<ILeakage>(leakageChangesRef, setHasLeakageChanges), [])}
                    getRowClassName={({ id }) => getRowClassName(id, leakageChangesRef)}
                    sx={sxStyle}
                    slots={{
                        toolbar: () => (
                            <GridToolbarContainer>
                                <Grid container alignContent={"space-between"}>
                                    <Grid item xs={9}>
                                        <Button disabled={isSaving} onClick={() => addLeakage()} startIcon={<AddIcon />}>{t('AddLeakage')}</Button>
                                        <Button disabled={!hasLeakageUnsavedRows || isSaving} onClick={() => discardChanges(leakageApiRef, leakageChangesRef, leakageColumns, setHasLeakageChanges)} startIcon={<RestoreIcon />}>{t('DiscardAllChanges')}</Button>
                                        <Button onClick={() => setIsOpen(true)} startIcon={<ImportIcon />}>{t('ImportLeakages')}</Button>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <LoadingButton disabled={!hasLeakageUnsavedRows} onClick={() => saveChanges(upsertLeakagesUrl, leakageChangesRef, setHasLeakageChanges, setLeakageData)} startIcon={<SaveIcon />} loading={isSaving} loadingPosition="start">{t('Save')}</LoadingButton>
                                        <Button onClick={() => { window.location.href = `/project/${id}/LDAR`; }} startIcon={<BackIcon />}>{t('Back')}</Button>
                                    </Grid>
                                </Grid>
                            </GridToolbarContainer>
                        ),
                    }}
                />
            </div>
            <Grid item xs={12} id="#ReplacementParts">
                <HeadingComponent value={t('ReplacementParts')} size="h6" />
            </Grid>
            <div style={{ height: 500 }}>
                <DataGridPro
                    density='compact'
                    loading={isSaving}
                    rows={partRows}
                    apiRef={partApiRef}
                    columns={partColumns}
                    autosizeOnMount={true}
                    isCellEditable={() => !props.denyEdit}
                    autosizeOptions={{ includeHeaders: true }}
                    getRowId={(row: GridValidRowModel) => row?.gridId}
                    processRowUpdate={React.useCallback(processRowUpdate<IReplacementPart>(partChangesRef, setHasPartChanges), [])}
                    getRowClassName={({ id }) => getRowClassName(id, partChangesRef)}
                    sx={sxStyle}
                    slots={{
                        toolbar: () => (
                            <GridToolbarContainer>
                                <Grid container alignContent={"space-between"}>
                                    <Grid item xs={9}>
                                        <Button disabled={isSaving} onClick={() => addPart()} startIcon={<AddIcon />}>{t('AddReplacementPart')}</Button>
                                        <Button disabled={!hasPartUnsavedRows || isSaving} onClick={() => discardChanges(partApiRef, partChangesRef, partColumns, setHasPartChanges)} startIcon={<RestoreIcon />}>{t('DiscardAllChanges')}</Button>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <LoadingButton disabled={!hasPartUnsavedRows} onClick={() => saveChanges(upsertPartUrl, partChangesRef, setHasPartChanges, setPartData)} startIcon={<SaveIcon />} loading={isSaving} loadingPosition="start">{t('Save')}</LoadingButton>
                                        <Button onClick={() => { window.location.href = `/project/${id}/LDAR`; }} startIcon={<BackIcon />}>{t('Back')}</Button>
                                    </Grid>
                                </Grid>
                            </GridToolbarContainer>
                        ),
                    }}
                />
            </div>
        </div>
    );
}