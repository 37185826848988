// @ts-expect-error not converted yet
import { ProjectsAndRequestsLists } from "./components/ProjectsAndRequestsLists/ProjectsAndRequestsLists";
// @ts-expect-error not converted yet
import { Project } from "./components/Project/Project";
import { PrivateRoute, PublicRoute } from "./components/PrivateRoute/PrivateRoute";
import { MainHeader } from "./components/MainHeader/MainHeader";
import ReplacementPartsCart from "./components/ReplacementPartsCart/ReplacementPartsCart";
import SettingsPanel from "./components/Settings/SettingsPanel";
// @ts-expect-error not converted yet
import QrCodeAudit from "./components/QrCodeAudit/QrCodeAudit";
import ScrollComponent from "./components/base/ScrollComponent";
import * as Roles from './helper/AuthorizationHelper/AuthConstatants';
import { ErrorNotification } from "./helper/ApiInterceptorHelper";
import { GlobalSpinner } from "./helper/SpinnerHelper";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import Maintenance from "./components/Maintenance/Maintenance";
import { Inquiry } from "./components/Inquiry/Inquiry";
import { parseRouteLngToLocale } from './components/Reusable/MaterialUIStyles/StyleLocales';
import CountryLanguageSelection from "./components/CountryLanguageSelection/CountryLanguageSelection";
import { FestchDropDownLists } from './helper/GlobalVariables'
import InitializeMessage from './components/InitializeMessage/InitializeMessage'
import SideWrapper from "./components/base/SideWrapper";
import { ErrorPage } from "./components/base/ErrorPage";
import { ThankYou } from "./components/base/ThankYou";
import { Logout } from "./components/base/Logout";
import { Footer } from "./components/base/Footer";
import { Home } from "./components/Home/Home";
import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { SnackbarComponent } from './components/Reusable';
import { theme } from './components/Reusable/MaterialUIStyles/Styles';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material';
import { useGlobalState } from 'state-pool';
import axios from 'axios';
import "./styles.css";
import { getLocale } from './helper/CountryLanguages';


interface IProps {
    handleSideBar: (show: boolean, iconHide?: boolean) => void;
    showContent?: boolean;
    show: boolean;
}

export default function App() {
    const maintenanceFlagUrl = '/maintenance/maintenanceFlag.txt';
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [initDataFetched, setInitFetch] = useState(false);
    const [initLanguage] = useGlobalState("InitLanguage", { default: false, persist: true });

    useEffect(() => {
        checkMaintenanceStatus();
    }, []);

    const isSiteOnMaintenance = async () => {
        try {
            const res = await axios.get(maintenanceFlagUrl);
            const isMaint = res.status === 200;
            return isMaint;
        } catch {
            return false;
        }
    }

    const checkMaintenanceStatus = async () => {
        const isMaint = await isSiteOnMaintenance();
        if (isMaint) {
            setIsMaintenance(true);
        } else {
            await FestchDropDownLists();
            setInitFetch(true);
        }
    }

    function BaseContent() {
        const { lng } = getLocale();
        const [themeWithLocale, setThemeWithLocale] = useState(theme);

        useEffect(() => {
            setThemeWithLocale(createTheme(theme, parseRouteLngToLocale(lng)))
        }, [lng]);

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themeWithLocale}>
                    <React.Fragment>
                        <SnackbarComponent isGlobal={true} />
                        <GlobalSpinner />
                        <ErrorNotification />
                        <ScrollComponent />
                        <SideWrapper>
                            {(props) => (
                                window.location.pathname != "/logout" ?
                                    <>
                                        <MainHeader {...props} />
                                        {initDataFetched ?
                                            (
                                                <Page
                                                    handleSideBar={props.handleSideBar}
                                                    showContent={props.showContent}
                                                    show={props.show}
                                                />
                                            ) :
                                            (
                                                <InitializeMessage title="InitializingDataMessage" />
                                            )}
                                        <Footer />
                                    </>
                                    :
                                    <Logout />
                            )}
                        </SideWrapper>
                    </React.Fragment>
                </ThemeProvider>
            </StyledEngineProvider>
        )
    }

    return (
        <React.Fragment>
            {isMaintenance ?
                <Maintenance />
                :
                <Route
                    path={`/`}
                    component={BaseContent}
                />
            }
        </React.Fragment>
    );

    function Page(props: IProps) {
        const { handleSideBar, showContent } = props;

        return (
            <div className="content-max-height">
                {
                    initLanguage === true ?
                        <CountryLanguageSelection />
                        :
                        <Switch>
                            <PublicRoute
                                path={`/unsubscribe/:email`}
                                globalContentId="unsubscribe-page"
                                component={Unsubscribe}
                            />
                            <PrivateRoute
                                path={`/project/:id?/order`}
                                globalContentId="order-page"
                                handleSideBar={handleSideBar}
                                component={ReplacementPartsCart}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.AccountAdmin, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/project/:id?/validate`}
                                globalContentId="validate-page"
                                handleSideBar={handleSideBar}
                                component={QrCodeAudit}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.AccountAdmin]}
                            />
                            <PrivateRoute
                                exact
                                path={`/projectsandrequests/:anypath?`}
                                globalContentId="projects-and-requests-page"
                                component={ProjectsAndRequestsLists}
                                handleSideBar={handleSideBar}
                            />
                            <PublicRoute
                                path={`/contact`}
                                globalContentId="contact-page"
                                render={(props) => <Inquiry {...props} handleSideBar={handleSideBar} />}
                            />
                            <PrivateRoute
                                path={`/SettingsPanel`}
                                globalContentId="settings-page"
                                component={SettingsPanel}
                                handleSideBar={handleSideBar}
                            />
                            <PrivateRoute
                                path={`/request/:id?`}
                                globalContentId="inquiry-request-page"
                                component={Inquiry}
                                handleSideBar={handleSideBar}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.AccountAdmin]}
                            />
                            <PrivateRoute
                                path={`/project/:id?`}
                                globalContentId="project-page"
                                handleSideBar={handleSideBar}
                                showContent={showContent}
                                component={Project}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.AccountAdmin, Roles.Reader, Roles.Editor]}
                            />
                            <PublicRoute
                                path={`/ThankYou`}
                                globalContentId="thank-you-page"
                                component={ThankYou}
                            />
                            <PublicRoute
                                path={`/error-page/:errorCode?/:errorMessage?`}
                                globalContentId="error-page"
                                render={({ match }) => <ErrorPage errorCode={match.params.errorCode ?? "404"} errorMessage={match.params.errorMessage ?? "PageNotFoundError"} />}
                            />
                            <PublicRoute
                                exact
                                path={`/`}
                                globalContentId="home-page"
                                render={(props) => <Home {...props} handleSideBar={handleSideBar} />}
                            />
                            <PublicRoute
                                path="*"
                                globalContentId="not-found-page"
                                render={() => <ErrorPage errorCode={"404"} errorMessage={"PageNotFoundError"} />}
                            />
                        </Switch>
                }
            </div>
        );
    }
}