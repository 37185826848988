import React, { useState, useEffect } from 'react'
import { IconButton, Grid } from '@mui/material';
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import SettingsNavigation from './SettingsNavigation';
import * as Icon from '@mui/icons-material/';
import RestrictedSettings from './Content/RestrictedSettings/RestrictedSettings';
import AlertManagement from './Content/AlertManagement/AlertManagement';
import TranslationsSettings from './Content/TranslationsSettings/TranslationsSettings';
import AccountManagement from './Content/ExecutorSettings/AccountManagement';
import BreadcrumbsComponent from "../Breadcrumbs/Breadcrumbs";
import AccountEdit from './Content/ExecutorSettings/AccountEdit';
import UserManagement from './Content/UserManagement/UserManagement';
import AddEditUser from './Content/UserManagement/AddEditUser';
import AccountUserEdit from './Content/ExecutorSettings/AccountUsers/AccountUserEdit';
import ReportGeneration from './Content/ReportGeneration/ReportGeneration';
import MassEmailNotifications from './Content/MassEmailNotifications/MassEmailNotifications';
import LicenseRequests from './Content/LicenseRequests/LicenseRequests';
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants';
import { HasUserRole } from '../../helper/AuthorizationHelper/AuthHelper';
import { UserStore } from '../../store';
import "./SettingsPanel.css"

interface IProps {
	handleSideBar: (show: boolean, iconHide?: boolean) => void;
}

export default function SettingsPanel(props: IProps) {
	const [mobileOpen, setMobileOpen] = useState(false);
	const [user, setUser] = useState({})

	const handleClose = () => {
		setMobileOpen(false)
	}

	useEffect(() => {
		const userSub = UserStore.user.subscribe(user => {
			setUser(user);
		})
		return () => userSub.unsubscribe();
	})

	useEffect(() => {
		props.handleSideBar(true);
	}, [])

	return (
		<div className="admin-panel">
			<IconButton
				onClick={() => setMobileOpen(!mobileOpen)}
				className="mobileView"
				id="btn_mobileView-button"
				size="large"
			>
				<Icon.Menu />
			</IconButton>
			<Grid container className="flex">
				{HasUserRole([], user) ?
					<div className="admin-navigation-container">
						<Grid item className={`admin-navigation ${mobileOpen ? "nav-active" : ""}`}>
							<SettingsNavigation handleClose={handleClose} mobileOpen={mobileOpen} />
						</Grid>
					</div>
					: null}
				<Grid item className="admin-content">
					<Grid className="top-bar country" item md={12} xs={12} >
						{HasUserRole([], user) ?
							<Grid item >
								<BreadcrumbsComponent />
							</Grid>
							: null}
					</Grid>
					<Switch>
						<PrivateRoute
							path={`/SettingsPanel/UserManagement/add-edit/:id?`}
							globalContentId="settings-user-management-page"
							component={AddEditUser}
						/>
						<PrivateRoute
							exact
							path={`/SettingsPanel/UserManagement`}
							globalContentId="settings-user-management-page"
							component={UserManagement}
						/>
						<PrivateRoute
							path={`/SettingsPanel/ExecutorManagement/:id?/AddEditExecutor/User/:cid?`}
							globalContentId="settings-account-management-page"
							component={AccountUserEdit}
						/>
						<PrivateRoute
							path={`/SettingsPanel/ExecutorManagement/:id?/AddEditExecutor/`}
							globalContentId="settings-account-management-page"
							component={AccountEdit}
						/>
						<PrivateRoute
							exact
							path={`/SettingsPanel/ExecutorManagement`}
							globalContentId="settings-account-management-page"
							component={AccountManagement}
						/>
						<PrivateRoute
							path={`/SettingsPanel/TranslationSettings`}
							globalContentId="settings-translation-settings-page"
							component={TranslationsSettings}
							allowedRoles={[Roles.SuperAdmin]}
						/>
						<PrivateRoute
							path={`/SettingsPanel/RestrictedSettings`}
							globalContentId="settings-restricted-settings-page"
							component={RestrictedSettings}
							allowedRoles={[Roles.SuperAdmin]}
						/>
						<PrivateRoute
							path={`/SettingsPanel/AlertManagement`}
							globalContentId="settings-alert-managemenet-page"
							component={AlertManagement}
							allowedRoles={[Roles.SuperAdmin]}
						/>
						<PrivateRoute
							path={`/SettingsPanel/ReportGeneration`}
							globalContentId="settings-report-generation-page"
							component={ReportGeneration}
							allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin]}
						/>
						<PrivateRoute
							path={`/SettingsPanel/MassEmailNotifications`}
							globalContentId="settings-mass-email-notif-page"
							component={MassEmailNotifications}
							allowedRoles={[Roles.SuperAdmin]}
						/>
						<PrivateRoute
							path={`/SettingsPanel/LicenseRequests`}
							globalContentId="settings-license-request-page"
							component={LicenseRequests}
							allowedRoles={[Roles.SuperAdmin]}
						/>
					</Switch>
				</Grid>
			</Grid>
		</div >
	);
}