import { AuthenticationContext } from "react-adal";

export const adalConfig = {
    instance: process.env.REACT_APP_INSTANCE_ADAL,
    tenant: "adfs",
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    endpoints: {
        "adfs": "https://login.festo.com",
    },
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    popUp: true,
};

export const authContext = new AuthenticationContext(adalConfig)

export const logOut = () => new Promise((resolve, reject) => {
    const idToken = sessionStorage.getItem('adal.idtoken');
    if (idToken) {
        Object.entries(sessionStorage)
            .filter(x => x[0].substring(0, 4) === "adal")
            .forEach(x => sessionStorage.removeItem(x[0]));

        // Redirecting to logout URL
        window.location.href = `${process.env.REACT_APP_INSTANCE_ADAL}adfs/oauth2/logout?id_token_hint=${idToken}`;
        resolve("Logout initiated"); // Resolve immediately as the logout is initiated
    } else {
        reject("No ID token found for logout.");
    }
});

export const replaceAuth = true;