import React, { Suspense } from 'react';
import * as serviceWorker from './registerServiceWorker';
import { SpinnerComponent } from './components/Reusable';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license';
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import App from './App';
import './i18n';
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig: AuthProviderProps = {
    client_id: process.env.REACT_APP_PINGONE_CLIENT_ID as string,
    authority: process.env.REACT_APP_PINGONE_CLIENT_URL as string,

    response_type: 'code',
    response_mode: 'fragment',

    redirectTarget: 'top',
    scope: 'openid profile',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),

    redirect_uri: `${window.location.origin}/`,
    post_logout_redirect_uri: `${window.location.origin}/logout`,

    popup_redirect_uri: `${window.location.origin}/`,
    popup_post_logout_redirect_uri: `${window.location.origin}/logout`,
    //popupWindowFeatures: "menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes",

    onSigninCallback: (): void => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
        const pathname = sessionStorage.getItem('pathname');
        if (pathname != null) {
            window.location.href = pathname;
            sessionStorage.removeItem('pathname');
        } else {
            window.location.href = '/projectsandrequests';
        }
    },
};

LicenseInfo.setLicenseKey('459c3c4a338b6996588c3dc1ad5010cdTz0xMDE0MzAsRT0xNzYyNjEzNDY3MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');
const container = document.getElementById('app') ?? new HTMLElement;
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <Suspense fallback={<SpinnerComponent open={true} />}>
            <AuthProvider {...oidcConfig}>
                <App />
            </AuthProvider>
        </Suspense>
    </BrowserRouter>
);
serviceWorker.unregister();