export const TranslationLanguages = "en|fr|es|ca|de|zh|cz|hu|jp|ko|lt|nl|pl|pt|ru|sv|sk|in|id|it|ro|th|my|vi|translation_keys";

interface Country {
    countryCode: string; languageList: string[]
}

export const UsedCountries: Country[] = [
    { countryCode: "AR", languageList: ["es", "en", "translation_keys"] },
    { countryCode: "AU", languageList: ["en"] },
    { countryCode: "AT", languageList: ["de", "en"] },
    { countryCode: "BE", languageList: ["nl", "en", "fr", "de"] },
    { countryCode: "BR", languageList: ["pt", "en"] },
    { countryCode: "BG", languageList: ["en"] },
    { countryCode: "CA", languageList: ["en", "fr"] },
    { countryCode: "CL", languageList: ["es", "en"] },
    { countryCode: "CN", languageList: ["zh", "en"] },
    { countryCode: "CO", languageList: ["es", "en"] },
    { countryCode: "HR", languageList: ["en"] },
    { countryCode: "CZ", languageList: ["cz", "en"] },
    { countryCode: "DK", languageList: ["en"] },
    { countryCode: "EE", languageList: ["en"] },
    { countryCode: "FI", languageList: ["en"] },
    { countryCode: "FR", languageList: ["fr", "en"] },
    { countryCode: "DE", languageList: ["de", "en"] },
    { countryCode: "GB", languageList: ["en"] },
    { countryCode: "GR", languageList: ["en"] },
    { countryCode: "HK", languageList: ["zh", "en"] },
    { countryCode: "HU", languageList: ["hu", "en"] },
    { countryCode: "IN", languageList: ["en"] },
    { countryCode: "ID", languageList: ["id", "en"] },
    { countryCode: "IE", languageList: ["en"] },
    { countryCode: "IT", languageList: ["it", "en"] },
    { countryCode: "JP", languageList: ["jp", "en"] },
    { countryCode: "KR", languageList: ["ko", "en"] },
    { countryCode: "LT", languageList: ["lt", "en"] },
    { countryCode: "MY", languageList: ["my", "en"] },
    { countryCode: "MX", languageList: ["es", "en"] },
    { countryCode: "NL", languageList: ["nl", "en"] },
    { countryCode: "NZ", languageList: ["en"] },
    { countryCode: "NO", languageList: ["en"] },
    { countryCode: "PE", languageList: ["es", "en"] },
    { countryCode: "PH", languageList: ["en"] },
    { countryCode: "PL", languageList: ["pl", "en"] },
    { countryCode: "PT", languageList: ["pt", "en"] },
    { countryCode: "RO", languageList: ["ro", "en", "hu"] },
    { countryCode: "RU", languageList: ["ru", "en"] },
    { countryCode: "SG", languageList: ["en"] },
    { countryCode: "SK", languageList: ["sk", "en"] },
    { countryCode: "SI", languageList: ["en"] },
    { countryCode: "VN", languageList: ["vi", "en"] },
    { countryCode: "ZA", languageList: ["en"] },
    { countryCode: "ES", languageList: ["es", "ca", "en"] },
    { countryCode: "SE", languageList: ["sv", "en"] },
    { countryCode: "CH", languageList: ["de", "en", "fr"] },
    { countryCode: "TW", languageList: ["zh", "en"] },
    { countryCode: "TH", languageList: ["th", "en"] },
    { countryCode: "TR", languageList: ["en"] },
    { countryCode: "US", languageList: ["en"] }
];

export const UsedCountryList = UsedCountries.reduce((previousValue, currentValue) => previousValue == "" ? previousValue + currentValue.countryCode.toLowerCase() : previousValue + "|" + currentValue.countryCode.toLowerCase(), "");

export const i18nCountryLanguageTranslationWhitelist = [
    'en', 'fr', 'es', 'de', 'EN', 'DE', 'ES', 'FR',
    'zh', 'cz', 'hu', 'jp', 'ko', 'nl', 'pl', 'pt', 'ru', 'sv', 'in',
    'ZH', 'CZ', 'HU', 'JP', 'KO', 'NL', 'PL', 'PT', 'RU', 'SV', 'IN', 'SK', 'sk', 'CA', 'ca',
    'it', 'IT', 'ro', 'RO', 'id', 'ID', 'th', 'TH', 'my', 'MY', 'vi', 'VI'
];

export const getLocale = () => {
    const country = localStorage.getItem('country') ?? 'us';
    const lng = localStorage.getItem('language') ?? 'en';
    return { country, lng }
}