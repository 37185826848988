import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { styled } from '@mui/material';
import { getLocale } from '../../../helper/CountryLanguages';

const StyledPhoneInput = styled(PhoneInput)((model) => ({
    paddingTop: "18px !important",
    "&:focus-within": {
        color: model.isValid == false ? '#f44336' : "#0091dc"
    },
    color: model.isValid == false ? '#f44336' : 'rgba(0, 0, 0, 0.5)',
    [`& #input_${model.idkey}.form-control`]: {
        border: 0,
        borderBottom: model.value == "" || model.isValid == false ? '2px solid #f44336' : "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        height: "31px",
        padding: "12px 14px 15px 45px",
        width: "100%"
    },
    [`& #input_${model.idkey}.form-control:hover`]: {
        borderBottom: model.value == "" || model.isValid == false ? '2px solid #f44336' : "2px solid rgba(0, 0, 0, 0.87)"
    },
    [`& #input_${model.idkey}.form-control:focus`]: {
        border: 0,
        borderBottom: model.value == "" || model.isValid == false ? '2px solid #f44336' : "2px solid #0091dc",
        boxShadow: "none",
        borderRadius: 0
    },
    [`& #input_${model.idkey}.form-control::placeholder`]: {
        color: "#f44336"
    },
    [`& #input_${model.idkey}.form-control:focus::placeholder`]: {
        color: "transparent"
    },
    "& .special-label": {
        left: "0 !important",
        top: "0 !important",
        font: 'Normal 0.75rem Meta Pro !important',
        padding: "0 !important",
        fontSize: '1rem',
        fontFamily: 'Meta Pro',
        fontWeight: 400,
        lineHeight: "1 !important",
    },
    "& .selected-flag": {
        padding: "0 !important",
    },
    "& .flag-dropdown": {
        paddingTop: "10px !important",
        top: "0 !important",
        marginTop: "0 !important",
    },
}));


const PhoneNumberInputField = (props) => {
    const { country } = getLocale();
    let { t } = useTranslation();
    const { disabled, model, onChange, tabIndex } = props;

    return (
        <StyledPhoneInput
            {...model}
            inputProps={{ id: "input_" + model.idkey, tabIndex: tabIndex ?? 0 }}
            disabled={disabled === true}
            country={country}
            value={model.value}
            specialLabel={model.value != "" ? t("PhoneNumber") + " *" : ""}
            placeholder={t("PhoneNumber") + " *"}
            onChange={onChange}
        />
    );
}

export default PhoneNumberInputField;