import React, { useState } from 'react'
import { API } from './ApiHelper'
import { SnackbarComponent } from '../components/Reusable'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { checkAuth } from '../store/UserStore';
import { useAuth } from 'react-oidc-context';

export const ErrorNotification = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const auth = useAuth();

    const genericErrorMsg = "GenericErrorContactAdminMsg";

    const [snackBarModel, setSnackBarModel] = useState({
        open: false,
        snackType: "",
        message: "",
        vertical: "bottom",
        horizontal: "center"
    });

    //Error handling interceptor
    API.interceptors.response.use(res => res, function (error) {
        let message = genericErrorMsg;

        if (!!error.response && error.response.status == 401) {
            message = "UnauthorizedRequestError";
            checkAuth(auth);
        }

        if (!!error.response && error.response.status == 404) {
            history.push(`/error-page/404/NotFound`);
            message = "PageNotFoundError";
        }

        if (!!error.response && error.response.status == 450)
            message = error.response.data?.ErrorText;

        displayError(message)

        return Promise.reject(error);
    });

    const displayError = (message: string) => {
        snackBarModel.open = true;
        snackBarModel.snackType = 'danger';
        snackBarModel.message = t(message);
        setSnackBarModel({ ...snackBarModel })
    }

    return (
        <React.Fragment>
            {snackBarModel.open == true && <SnackbarComponent model={snackBarModel} />}
        </React.Fragment>
    );
}