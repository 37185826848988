import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { TextFieldComponent, HeadingComponent, QrReaderButton } from '../Reusable/';
import { Close, Search, Forward } from '@mui/icons-material';
import { API } from '../../helper/ApiHelper';
import TableHeadingContent from '../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import * as Consts from '../../helper/ConstantRepository';
import { getLocale } from '../../helper/CountryLanguages';

export default function MaterialSearchDialog(props) {
    const { open, setOpen, type, model, insertSearchMaterial } = props;

    const searchUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "replacement-part/material-search";

    const { lng } = getLocale();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [searchResultList, setSearchResultList] = useState([]);
    const [filterList, setFilterList] = useState([])
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(5);

    useEffect(() => {
        if (open === true) {
            let searchValueParent = type == Consts.ReplacementPartOriginal ? model.partNumber.value : model.newPartNumber.value;
            if (searchValueParent?.length > 0 && (searchValue.length == 0 || searchValue != searchValueParent)) {
                setSearchValue(searchValueParent);
                searchMaterial(searchValueParent);
            }
        }
    }, [open]);

    const searchMaterial = async (searchString) => {
        if (searchString.length > 0) {
            let query = { searchString: searchString, country: lng };
            let searchResponse = await API.post(searchUrl, query);
            setSearchResultList(searchResponse);
            setFilterList(searchResponse);
            setPage(0);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleInputKeyPress = (e) => {
        if (e.keyCode == 13)
            searchMaterial(searchValue)
    }

    const pickMaterial = (rowId) => {
        let searchModel = searchResultList[rowId];
        insertSearchMaterial(searchModel, type);
        setOpen(false);
    }

    const filterFunction = (tempdata) => {
        setSearchResultList(tempdata);
        setPage(0);
    }

    const changeRows = (e) => {
        setRows(e.target.value);
        setPage(0);
    }

    const setQrCode = (code) => {
        let codeString = code.toString();
        setSearchValue(codeString)
        searchMaterial(codeString);
    }

    return (
        <Dialog open={open} className="MaterialSearchDialog ReplacementPartDialog_body">
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("MaterialSearch")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} direction="column" className="ReplacementPartMaterialSearch">
                    <Grid container item spacing={1} direction="row">
                        <Grid item xs={6}>
                            <TextFieldComponent
                                model={{ value: searchValue, isValid: true, label: t("Search") }}
                                onChange={e => setSearchValue(e.target.value)}
                                onKeyDown={handleInputKeyPress}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={() => searchMaterial(searchValue)}
                                id="btn_MaterialDialog-Search"
                                color="inherit"
                                size="large"
                            >
                                <Search fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <QrReaderButton
                                setCode={setQrCode}
                                id="material-search-qr"
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableHeadingContent
                            defaultData={searchResultList}
                            filterData={filterList}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.MaterialSearch}
                            NotShowAddDelete={true}
                            tableId={"MaterialSearch"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("PartNumber")}</TableCell>
                                        <TableCell>{t("Description")}</TableCell>
                                        <TableCell>{t("TypeCode")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        searchResultList.slice(page * rows, rows + (page * rows)).map((part, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                {Object.values(part).map((el, fieldIndex) =>
                                                    <TableCell id={"MatSearch-" + el + fieldIndex} key={rowIndex.toString() + fieldIndex.toString()}>
                                                        {el}
                                                    </TableCell>)
                                                }
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => pickMaterial(page * 5 + rowIndex)}
                                                        id="btn_MaterialDialog-Pick"
                                                        color="inherit"
                                                        size="large"
                                                    >
                                                        <Forward fontSize="large" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                rowsPerPageOptions={[5, 10, 15]}
                                count={searchResultList && searchResultList.length > 0 ? searchResultList.length : 0}
                                rowsPerPage={rows}
                                onRowsPerPageChange={changeRows}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}