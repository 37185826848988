import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
//import { useTranslation } from 'react-i18next';

export function Logout() {
    //const { t } = useTranslation();
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid
                id="logout-headline"
                textAlign="center"
            >
                <h2>Logout successful</h2>
                <p>You can close this popup!</p>
                <img
                    className="logoIMG"
                    src={"/pictures/EnergySavingServices_final.svg"}
                    alt="#"
                />
            </Grid>
        </Grid>
    );
}
