import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { IconButton, List, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Icon from '@mui/icons-material/';
import { HasUserRole } from '../../helper/AuthorizationHelper/AuthHelper'
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants'
import { UserStore } from '../../store'

interface IProps {
	mobileOpen: boolean,
    handleClose: () => void,
}

export default function SettingsNavigation(props: IProps) {
    const { mobileOpen, handleClose } = props;

    const tabNames = ["ExecutorSettings", "User Settings", "Translations", "RestrictedSettings", "AlertManagement", "ReportGeneration", "MassEmailNotifications", "LicenseRequests"];

    const { t } = useTranslation();
    const location = useLocation();   
    const history = useHistory();

    const [activeBtn, setActiveBtn] = useState<number | null>(null);
    const [user, setUser] = useState({});

    useEffect(() => {
        const paths = location.pathname.split("/");
        const page = paths[2];
        tabNames.map((item, index) => page == Routes(index) ? setActiveBtn(tabNames.indexOf(item)) : 0)
    }, [location]);

    useEffect(() => {
        const useSub = UserStore.user.subscribe(user => {
            setUser(user);
        });
        return () => useSub.unsubscribe();
    });

    const TabIcons = (index: number) => {
        switch (index) {
            case 0:
                return <Icon.Business />
            case 1:
                return <Icon.PersonAdd />
            case 2:
                return <Icon.Flag />
            case 3:
                return <Icon.Layers />
            case 4:
                return <Icon.Warning />
            case 5:
                return <Icon.Description />
            case 6:
                return <Icon.Email />
            case 7:
                return <Icon.AssignmentLate />
        }
    }

    const Routes = (index: number) => {
        switch (index) {
            case 0:
                return "ExecutorManagement";
            case 1:
                return "UserManagement";
            case 2:
                return "TranslationSettings";
            case 3:
                return "RestrictedSettings";
            case 4:
                return "AlertManagement";
            case 5:
                return "ReportGeneration";
            case 6:
                return "MassEmailNotifications";
            case 7:
                return "LicenseRequests";
        }
    }

    const UserRights = (item : string) => {
        if (item == "ReportGeneration" && HasUserRole([Roles.SuperAdmin, Roles.AccountAdmin], user))
            return true;
        else if (["Translations", "RestrictedSettings", "AlertManagement", "MassEmailNotifications", "LicenseRequests"].includes(item) && HasUserRole([Roles.SuperAdmin], user))
            return true;
        else if ((item == "ExecutorSettings" || item == "User Settings") && HasUserRole([], user))
            return true;
        else
            return false;
    }
    return (
        <div>
            {mobileOpen ? <IconButton
                onClick={() => handleClose()}
                className="mobileView-close"
                id="btn_mobileView-close-button"
                size="large"
            >
                <Icon.Close />
            </IconButton> : ""}
            <div style={{ marginTop: "20px" }}></div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        {t('Settings')}
                    </ListSubheader>
                }
            >
                {tabNames.map((item, index) => {
                    if (UserRights(item)) {
                        return (
                            <ListItem button className={`admin-button ${activeBtn == index ? "borderChange" : ""}`}
                                onClick={e => { e.preventDefault(); history.push(`/SettingsPanel/${Routes(index)}`); setActiveBtn(index) }}
                                key={index}
                            >
                                <ListItemIcon>
                                    {TabIcons(index)}
                                </ListItemIcon>
                                <ListItemText primary={t(item)} />
                            </ListItem>
                        )
                    }
                })}
            </List>
        </div>
    );
}
