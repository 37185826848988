import React, { useState, useEffect } from 'react'
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExecutorStore } from '../../../store';
import * as Consts from '../../../helper/ConstantRepository';

export default function ExecutorInfoView() {
    const { t } = useTranslation();
    const [executorSettings, setExecutorSettings] = useState({});
    const [executorSelection, setExecutorSelection] = useState(Consts.ExecutorSelectionNotInitialized);
    
    useEffect(() => {
        let execSub1 = ExecutorStore.executorSettings.subscribe(exec => {
            setExecutorSettings(exec);
        })

        let execSub2 = ExecutorStore.executorSelection.subscribe(exSelection => {
            setExecutorSelection(exSelection);
        })
        return () =>{
            execSub1.unsubscribe();
            execSub2.unsubscribe();
        }
    }, []);

    if (Object.keys(executorSettings).length == 0 && executorSelection == Consts.ExecutorSelectionNotInitialized) return null;
    else if (Object.keys(executorSettings).length > 0 && executorSelection > 0) {
        return (
            <Grid item md={12} className="projectMenu-firsttext">
                <Grid container className="inner-text">
                    <Grid item className="inner-text-festo">
                        {executorSettings.subsidiaryFestoName}
                    </Grid>
                    <Grid item className="inner-text-bodytext">
                        <p>{executorSettings.subsidiaryAddress}</p>
                        <p>{executorSettings.subsidiaryCity}</p>
                        <br />
                        <p>{executorSettings.country}</p>
                        <p>{executorSettings.firstName} {executorSettings.lastName}</p>
                        <p>{executorSettings.subsidiaryTelephone}</p>
                        <p>{executorSettings.email}</p>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else if (Object.keys(executorSettings).length == 0 && executorSelection === Consts.AllExecutors) {
        return (
            <Grid item md={12} className="projectMenu-firsttext">
                <Grid container className="inner-text">
                    <Grid item className="inner-text-festo">
                        {t("AllExecutors")}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    else if (Object.keys(executorSettings).length == 0 && (executorSelection == Consts.ExecutorNotSelected || executorSelection == Consts.Reader)) {
        return (
            <Grid item md={12} className="projectMenu-firsttext">
                <Grid container className="inner-text">
                    <Grid item className="inner-text-festo">
                        {t("CreateExecutorTitle")}
                    </Grid>
                    <Grid item className="inner-text-bodytext">
                        <p>{t("CreateExecutorText1")}</p>
                        <p>{t("CreateExecutorText2")}</p>
                        <p>{t("CreateExecutorText3")}</p>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else return null;
}
