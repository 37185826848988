import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nCountryLanguageTranslationWhitelist } from './helper/CountryLanguages';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

let fallbackLng = 'en';
if (process.env.REACT_APP_LANGUAGE_MODE === 'Development') {
  i18nCountryLanguageTranslationWhitelist.push('translation_keys');
  fallbackLng = 'translation_keys';
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallbackLng,
    whitelist: i18nCountryLanguageTranslationWhitelist,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: () => {
        return process.env.REACT_APP_BLOB_BASE_URL + 'locales/{{lng}}/translation.json';
      },
    },
    detection: {
      order: ['path'],
    },
  });

export default i18n;
