import { useState, useEffect } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { getFontName } from '../../../../helper/Pdf';
import { getLocale } from '../../../../helper/CountryLanguages';

export const usePdfStyles = () => {
    const [style, setStyle] = useState({});
    const { lng } = getLocale();
    useEffect(() => {
        let styleTemp = StyleSheet.create({
            page: {
                fontFamily: getFontName(lng),
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                fontSize: 11,
                padding: "3% 3% 1% 3%"
            },
            header: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            },
            logoWrapper: {
                width: 115,
                height: 115,
            },
            logo: {
                objectPosition: "center",
                objectFit: "contain",
                width: "100%",
                height: "100%"
            },
            table: {
                display: "table",
                position: "relative",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                width: "100%",
                borderRight: "1px solid black",
                flexDirection: "row",
                alignItems: "stretch"
            },
            tableHeadRow: {
                flexDirection: "row",
                borderBottom: "1px solid black"
            },
            tableHead: {
                textAlign: "center",
                fontFamily: getFontName(lng, true),
            },
            tableRow: {
                flexDirection: "row",
                alignSelf: 'stretch',
                borderBottom: "1px solid black"
            },
            tableRowHeader1: {
                width: '42.9%',
            },
            tableRowHeader2: {
                width: '42.9%',
            },
            tableRowHeader3: {
                width: '14.3%',
            },
            tableRowHeader: {
                width: '100%',
            },
            tableCell: {
                width: "25%",
                height: "100%",
                borderRight: "1px solid black"
            },
            tableCell2: {
                width: "33.33%",
                borderRight: "1px solid black"
            },
            tableCell3: {
                width: "14.3%",
                borderRight: "1px solid black"
            },
            tableCellHeight: {
                minHeight: 30
            },
            imageView: {
                maxHeight: 155,
                maxWidth: 175,
                margin: 5
            },
            bigImageView: {
                maxHeight: 310,
                maxWidth: 350,
                margin: 5
            },
            ldarImageView: {
                maxHeight: 180,
                maxWidth: 220,
                margin: 5
            },
            errorPhoto: {
                justifyContent: "center",
                alignItems: "center",
                flex: 1
            },
            imageContainer: {
                marginTop: "5px",
                border: "1px solid black",
                minHeight: 165,
                flexDirection: "row"
            },
            bold: {
                fontFamily: getFontName(lng, true),
            }
        });
        setStyle(styleTemp);
    }, [lng]);

    return style;
}
