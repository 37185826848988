import { useState, useEffect } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { getFontName } from '../../../helper/Pdf';
import { getLocale } from '../../../helper/CountryLanguages';

export const usePdfStyles = () => {
    const [style, setStyle] = useState({});
    const { lng } = getLocale();
    useEffect(() => {
        let styleTemp = StyleSheet.create({
            page: {
                fontFamily: getFontName(lng),
                flexDirection: 'column',
                backgroundColor: '#FFFFFF',
                padding: "59px 28px 85px 65px"
            },
            content: {
                width: "388px"
            },
            header: {
                position: "absolute",
                top: "150px",
                right: "30px"
            },
            logo: {
                height: 24,
                width: 134
            },
            fixedTopSpacer: {
                height: "200px"
            },
            flexContent: {
                fontSize: "10px",
            },
            heading: {
                fontSize: 18,
                marginTop: "2%",
                marginBottom: "2%"
            },
            pushedHeading: {
                paddingLeft: 100,
                fontSize: 10
            },
            boldHeading: {
                fontFamily: getFontName(lng, true),
                fontSize: 12,
                marginBottom: 10
            },
            regular: {
                fontFamily: getFontName(lng, false),
            },
            bold: {
                fontFamily: getFontName(lng, true),
            },
            text: {
                fontSize: 10
            },
            commentText: {
                fontSize: 10,
                width: 400
            },
            pageNumbers: {
                position: 'absolute',
                bottom: 28,
                right: 28,
                fontSize: "10px",
                textAlign: 'right',
                width: 50
            },
            spacer: {
                height: 15
            },
            Draft: {
                position: 'absolute',
                left: 0,
                right: 0,
                alignItems: 'center',
                justifyContent: "center",
                height: "100%",
            },
            DraftText: {
                color: 'lightgrey',
                opacity: 0.4,
                fontSize: 100,
                fontWeight: 'bold',
                fontFamily: getFontName(lng),
                textTransform: 'uppercase',
                transform: 'rotate(-45deg)',
                userSelect: 'none',
            },
            NotValidForCustomerProjectsText: {
                color: 'lightgrey',
                opacity: 0.4,
                fontSize: 40,
                fontWeight: 'bold',
                fontFamily: getFontName(lng),
                textTransform: 'uppercase',
                transform: 'rotate(-55deg)',
                userSelect: 'none',
            },
            hidden: {
                display: 'none' //doesnt work
            }
        });
        setStyle(styleTemp);
    }, [lng]);

    return style;
}

export const StyleTable = StyleSheet.create({
    table: {
        display: "table",
    },
    tableRow: {
        flexDirection: "row",
        borderLeft: "1px solid black",
    },
    tableColRowZero: {
        borderBottom: "3px solid black",
        borderRight: "1px solid black",
        flex: 1
    },
    tableCol: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        flex: 1
    },
    tableCell0: {
        marginTop: 5,
        marginBottom: 2,
        marginLeft: 1,
        fontSize: 10
    },
    tableCell: {
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 4,
        marginRight: 4,
        fontSize: 10,
        minHeight: 10,
    },
    colNoBottomBorder: {
        borderRight: "1px solid black",
        flex: 1
    },
    tableColTotal: {
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        flex: 1,
        fontFamily: getFontName(getLocale().lng, true),
    },
    tableCellTotal: {
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 4,
        marginRight: 4,
        fontSize: 10,
        minHeight: 10,
        fontFamily: getFontName(getLocale().lng, true),
    }
});

export const StyleTableNoBorder = StyleSheet.create({
    table: {
        display: "table",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        flex: 1
    },
    tableColColon: {
        width: "5%"
    },
    tableCell: {
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
    },
    tableCellTotal: {
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10,
        fontFamily: getFontName(getLocale().lng, true),
    }
});


export const FlexTable = StyleSheet.create({
    column: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1
    },
    rowItem: {
        flexGrow: 1,
        flexBasis: 0,
        borderRight: "1px solid black"
    },
    rowItemText: {
        marginBottom: 2,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 10
    }
});