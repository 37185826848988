
export const getLink = (projectId: number, editId : number, type: number) => {
    switch (type) {
        case 0:
            return (`/project/${projectId}/CAG/AddEditCompressor/${editId}/#validate`);
        case 1:
            return (`/project/${projectId}/AQ/IndividualDryer/${editId}`);
        case 2:
            return (`/project/${projectId}/AQ/AirQualityMeasurement/${editId}`);
        case 3:
            return (`/project/${projectId}/AN/DistributionLine/${editId}`);
        case 4:
            return (`/project/${projectId}/AN/AirReceiver/${editId}`);
        case 5:
            return (`/project/${projectId}/AN/PressureDropAnalysis/${editId}`);
        case 6:
            return (`/project/${projectId}/MACAC/Application/${editId}`);
        case 7:
            return (`/project/${projectId}/LDAR/Leakage/${editId}`);
        case 21:
            return (`/project/${projectId}/PS/#validate`);
        case 22:
            return (`/project/${projectId}/GI/#validate`);
        case 23:
            return (`/project/${projectId}/CAG/#validate`);
        case 24:
            return (`/project/${projectId}/AN/#validate`);
        default:
            return "";
    }
}