import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { LeakageModelFactory } from './LeakageModel';
import ImageViewer from '../../ImageViewer/ImageViewer';
import { Grid, TextareaAutosize } from '@mui/material';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, DatePickerComponent, CheckboxComponent, ButtonComponent, SingleSelectComponent, AutocompleteComponent, ListPreviousNext, QrReaderButton } from '../../../components/Reusable/';
import MeasurementId from '../../MeasurementId/MeasurementId';
import { API } from '../../../helper/ApiHelper';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import * as Mapper from '../../../helper/Mappers';
import * as Fmt from '../../../helper/Formatters';
import { NavigationOption } from '../../../helper/ConstantRepository';
import { OpenGlobalSnackbar, LeakageInputDevices, LeakageActions, LeakageRepairTimeEstimations, ScrollerToTag } from '../../../helper/GlobalVariables';
import { UserStore, ExecutorStore, DirtyStore } from '../../../store';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { checkAndValidateModel, validateSingleProperty } from '../../../helper/Validator';
import { getLocale } from '../../../helper/CountryLanguages';

export default function Application(props) {
    const { denyEdit } = props;
    const copyImagesUrl = process.env.REACT_APP_DEVFESSIMAGESERVICES_BASE + "blob/copy-images/";
    const ldUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-detection";
    const { t } = useTranslation();
    let { id, cid } = useParams();
    const { country } = getLocale();
    let history = useHistory();

    const [mainModel, setMainModel] = useState(LeakageModelFactory(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [machineOptions, setMachineOptions] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);
    const [isOptimizationSelected, setIsOptimizationSelected] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, LeakageModelFactory(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (cid > 0)
            loadExistingData();
        if (cid == 0)
            loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let userSub = UserStore.user.subscribe(user => {
            setUser(user);
        })
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => {
            userSub.unsubscribe();
            execSub.unsubscribe();
        }
    }, []);

    const loadExistingData = () => {
        API.get(`${ldUrl}/${id}/Leakage/${cid}`).then(resp => {
            setIsOptimizationSelected(resp.leakage.leakageOptimization);
            let mappedModel = Mapper.mapDataToModelValues(resp.leakage, LeakageModelFactory(t));
            let calcMappedModel = Mapper.mapMachingDataToModelValues(resp.indLeakageCalc, LeakageModelFactory(t));
            setMainModel({ ...mappedModel, ...calcMappedModel });
            setReplacementPartList(resp.leakage.replacementPartList);
            setBuildingOptions(resp.buildingOptions);
            setDepartmentOptions(resp.departmentOptions);
            setMachineOptions(resp.machineOptions);
            ScrollerToTag()
        })
    }

    const loadCleanPage = () => {
        loadInputOptions();
        setIsOptimizationSelected(false);
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(LeakageModelFactory(t));
        newModel.guid.value = guid;
        setMainModel(newModel);
        setReplacementPartList([]);
        scrollTop();
    }

    const loadInputOptions = () => {
        API.get(`${ldUrl}/${id}/leakage-input-options`).then(resp => {
            setBuildingOptions(resp.buildingOptions);
            setDepartmentOptions(resp.departmentOptions);
            setMachineOptions(resp.machineOptions);
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const validateFormModel = () => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel);
        setMainModel({ ...validatedModel });
        return isValid;
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        validateSingleProperty(mainModel, property);
        if (property == "partNumber" || property == "typeCode") {
            validateSingleProperty(mainModel, "manufacturer");
            validateSingleProperty(mainModel, "component");
        }
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateSelectModel = (property, value, list) => {
        mainModel[property].value = list.find(x => x.id == value);
        mainModel[property].isValid = true;
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    const clickCancel = () => {
        history.push(`/project/${id}/LDAR#LeakageDetection`);
    }

    const postForm = async navigationOption => {
        let isValid = validateFormModel();
        if (isValid === true) {
            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.replacementPartList = replacementPartList;
            let postModel = {
                projectId: id,
                Leakage: objectPostModel
            };

            let method = mainModel.id.value == 0 ? "post" : "put";
            await API({
                url: `${ldUrl}/${id}/Leakage/${mainModel.id.value}`,
                method,
                data: postModel,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t("SaveSuccessful"));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/project/${id}/LDAR/Leakage/${method === "post" ? componentId : cid}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/project/${id}/LDAR/Leakage/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            OpenGlobalSnackbar("danger", t('PleaseVerifyYourEntries'));
        }
        return isValid;
    }

    const copyForm = async () => {
        loadInputOptions();
        let guid = Mapper.getNewGuid();
        await copyImages(guid);
        mainModel.guid.value = guid;
        mainModel.measurementId.value = "";
        mainModel.leackageNumber.value = "";
        mainModel.id.value = 0;
        setMainModel({ ...mainModel });
        replacementPartList.forEach(x => {
            x.id = 0;
            x.guid = null;
        });
        scrollTop();
    }

    const copyImages = async (guid) => {
        let copyImagesPostObject = {
            sourceProjectId: id,
            sourceGroupId: ImageBlobConstants.LDARPrefix + mainModel.guid.value,
            targetGroupId: ImageBlobConstants.LDARPrefix + guid
        };
        await API.post(copyImagesUrl + id, copyImagesPostObject);
    }

    const setLeakageRepairedDefaults = (isRepaired) => {
        if (isRepaired === true) {
            mainModel.repairedBy.value = user.firstName + " " + user.lastName;
            mainModel.repairDate.value = new Date();
        }
        else {
            mainModel.repairedBy.value = "";
            mainModel.repairDate.value = null
        }
    }

    return (
        <div className="Leakage">
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#LeakageIdentification">
                            <HeadingComponent value={t('LeakageIdentification')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2} /*match MeasurementId spacing*/ direction="row">
                        <Grid item xs={6}>
                            <TextFieldComponent
                                disabled
                                t={t}
                                model={mainModel.leackageNumber}
                                onChange={e => updateModel("leackageNumber", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId
                        disabled={denyEdit}
                        element={mainModel.measurementId}
                        onChange={e => updateModel("measurementId", e)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.detectedDate}
                                onChange={e => updateModel("detectedDate", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent
                                disabled={denyEdit}
                                options={executorUsersOptions}
                                t={t}
                                model={mainModel.detectedBy}
                                onInputChange={(e, value) => updateModel("detectedBy", value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} id="#LeakageRepair">
                    <HeadingComponent value={t('LeakageRepair')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxComponent
                        model={mainModel.leakageRepaired}
                        onChange={e => {
                            updateModel("leakageRepaired", e.target.checked);
                            setLeakageRepairedDefaults(e.target.checked);
                        }}
                    />
                </Grid>
                {mainModel.leakageRepaired.value &&
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} md={6}>
                                <DatePickerComponent
                                    t={t}
                                    model={mainModel.repairDate}
                                    onChange={e => updateModel("repairDate", e)}
                                    maxDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompleteComponent
                                    options={executorUsersOptions}
                                    t={t}
                                    model={mainModel.repairedBy}
                                    onInputChange={(e, value) => updateModel("repairedBy", value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextFieldComponent
                                    t={t}
                                    model={mainModel.leakageRepairTimeRepairedInput}
                                    onChange={e => updateModel("leakageRepairTimeRepairedInput", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextareaAutosize
                                            id='textarea-LDAREDIT-repairComment'
                                            placeholder={t("Comment")}
                                            width="100%"
                                            minRows="5"
                                            type="text"
                                            value={mainModel.repairComment.value}
                                            onChange={e => updateModel("repairComment", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} id="#Location">
                    <HeadingComponent value={t('Location')} size="h1" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent
                                disabled={denyEdit}
                                options={buildingOptions}
                                t={t}
                                model={mainModel.building}
                                onInputChange={(e, value) => updateModel("building", value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent
                                disabled={denyEdit}
                                options={departmentOptions}
                                t={t}
                                model={mainModel.department}
                                onInputChange={(e, value) => updateModel("department", value)}
                            />
                        </Grid>
                        <Grid item xs={9} md={4}>
                            <AutocompleteComponent
                                disabled={denyEdit}
                                options={machineOptions}
                                t={t}
                                model={mainModel.machine}
                                onInputChange={(e, value) => updateModel("machine", value)}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <QrReaderButton
                                setCode={code => updateModel("machine", code)}
                                id="leakage-machine-qr"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} id="#Details">
                    <HeadingComponent value={t('Details')} size="h1" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CheckboxComponent
                        disabled={denyEdit}
                        model={mainModel.leakageOptimization}
                        onChange={e => {
                            updateModel("leakageOptimization", e.target.checked);
                            setIsOptimizationSelected(e.target.checked);
                            if (e.target.checked) {
                                updateModel("leakageInputDevice", null, false);
                                updateModel("leakageInputValue", null, false);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit || isOptimizationSelected}
                                id="leakageInputDevice"
                                listFromDb={LeakageInputDevices}
                                model={mainModel.leakageInputDevice}
                                t={t}
                                onChange={e => updateSelectModel("leakageInputDevice", e.target.value, LeakageInputDevices)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit}
                                id="LeakageAction"
                                listFromDb={LeakageActions}
                                model={mainModel.leakageAction}
                                t={t}
                                onChange={e => updateSelectModel("leakageAction", e.target.value, LeakageActions)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent
                                disabled={denyEdit || isOptimizationSelected}
                                t={t}
                                model={mainModel.leakageInputValue}
                                onChange={e => updateModel("leakageInputValue", InputRestrictions.Decimal(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit}
                                id="LeakageRepairTimeEstimated"
                                listFromDb={LeakageRepairTimeEstimations}
                                model={mainModel.leakageRepairTimeEstimated}
                                t={t}
                                onChange={e => updateSelectModel("leakageRepairTimeEstimated", e.target.value, LeakageRepairTimeEstimations)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent
                                disabled
                                t={t}
                                model={{
                                    value: Fmt.round(mainModel.leakageFlowConverted.value, country, 2),
                                    isValid: mainModel.leakageFlowConverted.isValid,
                                    label: mainModel.leakageFlowConverted.label
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CheckboxComponent
                                disabled={denyEdit}
                                model={mainModel.leakageRepearableProdTime}
                                onChange={e => updateModel("leakageRepearableProdTime", e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h1" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        id="leakage-comment"
                        type="text"
                        value={mainModel.leakageComment.value}
                        minRows="5"
                        onChange={e => updateModel("leakageComment", e.target.value)}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value &&
                        <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.LDARPrefix + mainModel.guid.value} />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent
                                id="leakage-cancel"
                                value={t('Cancel')}
                                color="secondary"
                                onChange={clickCancel}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                id="leakage-save"
                                value={t('Save')}
                                color="primary"
                                onChange={() => postForm(NavigationOption.Reload)}
                            />
                        </Grid>
                        {!denyEdit &&
                            <Grid item>
                                <ButtonComponent
                                    id="leakage-saveandcopy"
                                    value={t('SaveAndCopy')}
                                    color="primary"
                                    onChange={() => postForm(NavigationOption.Copy)}
                                />
                            </Grid>
                        }
                        {!denyEdit &&
                            <Grid item>
                                <ButtonComponent
                                    id="leakage-saveandnew"
                                    value={t('SaveAndNew')}
                                    color="primary"
                                    onChange={() => postForm(NavigationOption.Create)}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

