import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import * as Mapper from '../../../helper/Mappers';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, ButtonComponent, DatePickerComponent, AutocompleteComponent, CheckboxComponent, ListPreviousNext, QrReaderButton } from '../../../components/Reusable/';
import { Grid, TextareaAutosize } from '@mui/material';
import { API } from '../../../helper/ApiHelper';
import { AirReceiverModel } from './AirReceiverModel';
import ReplacementParts from '../../ReplacementParts/ReplacementParts';
import MeasurementId from '../../MeasurementId/MeasurementId';
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import ImageViewer from '../../ImageViewer/ImageViewer';
import { OpenGlobalSnackbar, ScrollerToTag } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import { validateSingleProperty, checkAndValidateModel } from '../../../helper/Validator';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { NavigationOption } from '../../../helper/ConstantRepository';


export default function AirReceiver(props) {
    const { denyEdit } = props;
    let anUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "air-network-analysis";
    let history = useHistory();
    const { t } = useTranslation();
    const { id, cid } = useParams();
    const [mainModel, setMainModel] = useState(AirReceiverModel(t));
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(mainModel, AirReceiverModel(t), setMainModel);
    }, [t]);

    useEffect(() => {
        if (cid > 0) loadExistingData();
        if (cid == 0) loadCleanPage();
    }, [cid]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const loadExistingData = () => {
        API.get(`${anUrl}/${id}/air-receiver/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.data, AirReceiverModel(t));
            setMainModel({ ...mappedModel });
            setReplacementPartList(resp.data.replacementPartList);
            ScrollerToTag()
        })
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadCleanPage = async () => {
        let guid = Mapper.getNewGuid();
        let newModel = _.cloneDeep(AirReceiverModel(t));
        setMainModel(Object.assign({}, newModel, { guid: { ...newModel.guid, value: guid } }));
        setReplacementPartList([]);
        scrollTop();
    }

    const copyForm = async () => {
        Mapper.copyForm(mainModel, setMainModel);
        replacementPartList.forEach(x => {
            x.id = 0;
            x.guid = null;
        });
        scrollTop();
    }

    const clickCancel = () => {
        history.push(`/project/${id}/AN#AirReceivers`);
    }

    const postForm = async (navigationOption) => {
        let [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            let objectPostModel = Mapper.extractValuesFromModel(mainModel);
            objectPostModel.replacementPartList = replacementPartList;
            let postModel = {
                ProjectId: id,
                AirReceiver: objectPostModel
            };

            let method = mainModel.id.value == 0 ? "post" : "put";

            API({
                url: `${anUrl}/${id}/air-receiver/${mainModel.id.value}`,
                method,
                data: postModel,
            }).then(async (componentId) => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t('SaveSuccessful'));
                switch (navigationOption) {
                    case NavigationOption.Reload:
                        history.push(`/project/${id}/AN/AirReceiver/${method === "post" ? componentId: cid}`);
                        break;
                    case NavigationOption.Create:
                        if (cid == 0) loadCleanPage();
                        history.push(`/project/${id}/AN/AirReceiver/0`);
                        break;
                    case NavigationOption.Copy:
                        await copyForm();
                        break;
                    default:
                        break;
                }
            });

        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    }

    const updateModel = (property, value) => {
        mainModel[property].value = value;
        validateSingleProperty(mainModel, property);
        setMainModel({ ...mainModel });
        DirtyStore.setIsDirty(postForm);
    }

    return (
        <div className="air-receiver">
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#AirReceivers">
                            <HeadingComponent value={t('AirReceivers')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId disabled={denyEdit} element={mainModel.measurementId} onChange={e => updateModel("measurementId", e)} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={mainModel.dateOfDetection}
                                onChange={e => updateModel("dateOfDetection", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent
                                disabled={denyEdit}
                                options={executorUsersOptions}
                                model={mainModel.detectedBy}
                                onInputChange={(e, value) => updateModel("detectedBy", value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxComponent model={mainModel.isRepairDone} onChange={e => { updateModel("isRepairDone", e.target.checked); }} />
                        </Grid>
                        {mainModel && mainModel.isRepairDone && mainModel.isRepairDone.value &&
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <DatePickerComponent
                                            t={t}
                                            model={mainModel.repairDate}
                                            onChange={e => updateModel("repairDate", e)}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AutocompleteComponent
                                            options={executorUsersOptions}
                                            model={mainModel.repairedBy}
                                            onInputChange={(e, value) => updateModel("repairedBy", value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextareaAutosize
                                            id='textarea-AIRRECEIV-repairComment'
                                            placeholder={t("Comment")}
                                            width="100%"
                                            minRows="5"
                                            type="text"
                                            value={mainModel.repairComment.value}
                                            onChange={e => updateModel("repairComment", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3} direction="column" >
                                <Grid item id="#Location">
                                    <HeadingComponent value={t('Location')} size="h6" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.building} onChange={e => {
                                        updateModel("building", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.department} onChange={e => {
                                        updateModel("department", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3} direction="row">
                                        <Grid item xs={9}>
                                            <TextFieldComponent
                                                disabled={denyEdit}
                                                t={t}
                                                model={mainModel.machine}
                                                onChange={e => updateModel("machine", e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <QrReaderButton
                                                setCode={code => updateModel("machine", code)}
                                                id="air-receiver-machine-qr"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3} direction="column" >
                                <Grid item>
                                    <HeadingComponent value={t('Details')} size="h6" />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.volume} onChange={e => {
                                        updateModel("volume", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={mainModel.workingPressure} onChange={e => {
                                        updateModel("workingPressure", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldComponent disabled={denyEdit} t={t} model={mainModel.year} onChange={e => {
                                        updateModel("year", e.target.value)
                                    }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DatePickerComponent
                                        disabled={denyEdit}
                                        t={t}
                                        model={mainModel.lsInspection}
                                        onChange={e => updateModel("lsInspection", e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id="textarea_AIRRECEIV-comment" type="text" value={mainModel.comment.value} minRows="5"
                        onChange={e => {
                            updateModel("comment", e.target.value)
                        }}
                    />
                </Grid>
                <Grid item>
                    {mainModel.guid.value && <ImageViewer groupId={ImageBlobConstants.AirReceiverPrefix + mainModel.guid.value} />}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item><ButtonComponent id="AIRRECEIV-Cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                    <Grid item><ButtonComponent id="AIRRECEIV-Save" value={t('Save')} color="primary" onChange={() => postForm(NavigationOption.Reload)}></ButtonComponent></Grid>
                    {!denyEdit && <Grid item><ButtonComponent id="AIRRECEIV-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm(NavigationOption.Copy)}></ButtonComponent></Grid>}
                    {!denyEdit && <Grid item><ButtonComponent id="AIRRECEIV-SaveAndNew" value={t('SaveAndNew')} color="primary" onChange={() => postForm(NavigationOption.Create)}></ButtonComponent></Grid>}
                </Grid>
            </Grid>
        </div>
    )
}

