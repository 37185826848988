import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextareaAutosize } from '@mui/material';
import { ButtonComponent, HeadingComponent, CheckboxComponent } from '../../../Reusable';
import { API } from '../../../../helper/ApiHelper';
import { IMassEmailNotificationsModel, MassEmailNotificationsModel } from './MassEmailNotificationsModel';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';

export default function MassEmailNotifications() {
    const { t } = useTranslation();
    const sendUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-email";
    const getUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-email-get";
    const updateUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "mass-email-update";
    const [model, setModel] = useState<IMassEmailNotificationsModel>(MassEmailNotificationsModel(t));

    const send = async () => {
        const postModel = Mapper.extractValuesFromModelRecursive(model);
        await API.post(sendUrl, postModel);
        OpenGlobalSnackbar("success", t("SaveSuccessful"));
    }

    useEffect(() => {
        loadMassEmail();
    }, []);

    const loadMassEmail = () => {
        API.get(getUrl).then(resp => {
            const temp = MassEmailNotificationsModel(t);
            temp.emailSignature.value = resp.emailSignature;
            setModel(temp)
        });
    }

    const update = async () => {
        const postModel = Mapper.extractValuesFromModelRecursive(model);
        const updateModel = {
            EmailSignature: postModel.emailSignature
        }
        await API.post(updateUrl, updateModel);
        OpenGlobalSnackbar("success", t("SaveSuccessful"));
    }

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
                <HeadingComponent value={t("MassEmailNotifications")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <CheckboxComponent
                    model={model.sendToFesto}
                    onChange={e => Mapper.updateModel("sendToFesto", e.target.checked, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckboxComponent
                    model={model.sendToExternal}
                    onChange={e => Mapper.updateModel("sendToExternal", e.target.checked, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckboxComponent
                    model={model.sendToProjectUsers}
                    onChange={e => Mapper.updateModel("sendToProjectUsers", e.target.checked, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailSubject")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize id="textarea_email-subject"  value={model.emailSubject.value} minRows="1"
                    onChange={e => Mapper.updateModel("emailSubject", e.target.value, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailBody")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize id="textarea_email-body" value={model.emailBody.value} minRows="5"
                    onChange={e => Mapper.updateModel("emailBody", e.target.value, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailSignature")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize id="textarea_email-signature" value={model.emailSignature.value} minRows="5"
                    onChange={e => Mapper.updateModel("emailSignature", e.target.value, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent value={t("SaveEmailSignature")} color="primary" onChange={update} id="mass-email_update" />
                    </Grid>
                    <Grid item>
                        <ButtonComponent value={t("Send")} color="primary" onChange={send} id="mass-email_send" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
