import { useState, useEffect } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { getFontName } from '../../../helper/Pdf';
import { getLocale } from '../../../helper/CountryLanguages';

export const usePdfStyles = () => {
    const [style, setStyle] = useState({});
    const { lng } = getLocale()
    useEffect(() => {
        let styleTemp = StyleSheet.create({
            page: {
                fontFamily: getFontName(lng),
                flexDirection: 'column',
                backgroundColor: '#FFFFFF'
            },
            pageBody: {
                margin: "3%"
            },
            header: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            },
            logoWrapper: {
                width: 115,
                height: 115,
                marginTop: 15,
                marginRight: 15
            },
            logo: {
                objectPosition: "center",
                objectFit: "contain",
                width: "100%",
                height: "100%"
            },
            heading: {
                fontSize: 18
            },
            inputContainer: {
                borderStyle: "solid",
                borderBottomWidth: 1
            },
            label: {
                fontSize: 10,
                paddingTop: 1
            },
            inputLabel: {
                paddingBottom: 15,
                fontSize: 7
            },
            flexColumn: {
                display: "flex"
            },
            flexRow: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch"
            },
            flexRowStart: {
                display: "flex",
                flexDirection: "row",
                marginBottom: "1%",
                marginTop: "1%"
            },
            flexItem: {
                marginRight: 10,
                flexGrow: 1,
                flexBasis: 0,
                marginBottom: "1%",
                marginTop: "1%"
            },
            flexColumnItem: {
                flexGrow: 1,
                marginBottom: "1%",
                marginTop: "1%"
            },
            flexItemNoGrow: {
                marginRight: 10
            },
            checkbox: {
                width: 0,
                padding: 6,
                borderStyle: "solid",
                borderWidth: 1
            },
            table: {
                display: "table",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                borderBottom: "1px solid grey",
                borderRadius: "4px"
            },
            tableRow: {
                flexDirection: "row"
            },
            tableCol0: {
                width: "3%",
                borderBottom: "1px solid rgba(224, 224, 224, 1)"
            },
            tableCol: {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                flexGrow: 1,
                flexBasis: 0
            },
            tableColFlexEnd: {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                flexGrow: 1,
                flexBasis: 0,
                justifyContent: 'flex-end'
            },
            tableCell0: {
                marginTop: 5,
                marginBottom: 2,
                marginLeft: 1,
                fontSize: 10
            },
            tableCell: {
                marginTop: 5,
                marginBottom: 2,
                marginLeft: 5,
                marginRight: 5,
                fontSize: 10,
                minHeight: 15
            },
            tableCellInput: {
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 5,
                marginRight: 5,
                fontSize: 10,
                height: 20,
                borderBottom: "1px solid rgba(0, 0, 0, 1)"
            }
        });
        setStyle(styleTemp);
    }, [lng]);

    return style;
}
