import { BehaviorSubject } from "rxjs";
import { API } from '../helper/ApiHelper';

export interface CountryListResponse {
    countryId: number,
    countryName: string,
    countryCode: string,
    countryCurrency: string,
    countryPostalFormat: string,
    countryPostalRegex: string
}
const inqUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management";
const countryList = new BehaviorSubject<CountryListResponse[]>([]);
let language = '';
const CountryListStore = {
    countryList,
    initCountryList: function (lng: string) {
        if (language != lng || !Array.isArray(countryList.value) || countryList.value.length == 0)
            API.get<CountryListResponse[]>(`${inqUrl}/countries/${lng}`).then((resp) => {
                countryList.next(resp);
            });
        language = lng;
    }
}

export {
    CountryListStore
}