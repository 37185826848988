import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent, NumberInputFieldComponent, HeadingComponent, CheckboxComponent, ButtonComponent, HelperTextComponent, AutocompleteDropdown, SingleSelectComponent2, ModalComponent, LinkComponent, PhoneNumberInputField } from '../Reusable';
import { TextareaAutosize, Typography } from '@mui/material';
import { InquiryModel, IInquiryModel, IInquiry } from './InquiryModel'
// @ts-expect-error not converted yet
import { CompressorTable } from './CompressorTable/CompressorTable';
import { CompressorModel, ICompressorModel } from './CompressorTable/CompressorModel';
import { isAnyServiceSelectedValidator, validateExceptionFields, markOrUnmarkCompressorChecks } from './InquiryValidators';
// @ts-expect-error not converted yet
import { isValidModel, validateModel, checkAndValidateModelArray } from '../../helper/Validator';
// @ts-expect-error not converted yet
import * as Mapper from '../../helper/Mappers';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { API } from '../../helper/ApiHelper';
import * as Fmt from '../../helper/Formatters';
import * as InputRestrictions from '../../helper/InputRestrictions';
import { OpenGlobalSnackbar, MeasurementSystems, Salutations } from '../../helper/GlobalVariables';
import { ExecutorStore, IAccountSelection, IAccountSettings } from '../../store/AccountStore';
import { CountryListResponse, CountryListStore } from '../../store/CountryListStore';
import { useIsMount } from '../../helper/UseIsMount';
import * as Consts from '../../helper/ConstantRepository';
import {getLocale} from '../../helper/CountryLanguages';
import BreadcrumbsComponent from '../Breadcrumbs/Breadcrumbs';
import { getPrivacyPolicyLink } from '../../helper/BaseActions';
// @ts-expect-error not converted yet
import PdfReportLeakageEstimation from './PdfReportLeakageEstimation';
// @ts-expect-error not converted yet
import ImportFullDialog from '../ImportDryerCompressorFull/ImportFullDialog';
import * as DateHelper from '../../helper/DateHelper';
import { IMaskInput } from 'react-imask';
import { IdParam, ModalType, ModalTypeInit } from '../Types/Types';
import { ICheckboxComponent } from '../Reusable/CheckBox/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';
import './Inquiry.css';

interface ILicenseTypeCount {
    id: number,
    count: number,
    expirationTime: Date
    value: string
}

interface IConvertToProjectResponse {
    id: number,
    projectId: string
}

interface IProps {
    handleSideBar: (show: boolean, iconHide?: boolean) => void,
}

export function Inquiry(props: IProps) {
    const reqUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "request-management";
    const inqUrl = process.env.REACT_APP_DEVFESSINQUIRYSERVICES_BASE + "inquiry-management";

    const history = useHistory();
    const isMount = useIsMount();
    const location = useLocation();
    const { t } = useTranslation();

    const { id} = useParams<IdParam>();
    const { lng } = getLocale();
    const [executorIdParameter, setExecutorIdParameter] = useState(0);
    const [executorName, setExecutorName] = useState("");
    const [rawInquiryModel, setRawInquiryModel] = useState<IInquiry>({});
    const [compressorModel, setCompressorModel] = useState<ICompressorModel[]>([]);
    const [controlTypes, setControlTypes] = useState([]);
    const [isAtLeastOneServiceSelected, setAtLeastOneServiceSelected] = useState<boolean>(true);
    const [importOpen, setImportOpen] = useState<boolean>(false);
    const [isAuthorisedRoute] = useState<boolean>(location.pathname.indexOf("request") != -1);
    const [isPolicyAgreedSelected, setIsPolicyAgreedSelected] = useState<boolean>(isAuthorisedRoute);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [leakageDetectionInDays, setLeakageDetectionInDays] = useState<number>(0);
    const [countryList, setCountryList] = useState<CountryListResponse[]>([]);
    const [executorList, setExecutorList] = useState<IAccountSelection[]>([]);
    const [inquiryModel, setInquiryModel] = useState<IInquiryModel>(InquiryModel(t, isAuthorisedRoute));
    const [licenseTypeList, setLicenseTypeList] = useState<ILicenseTypeCount[]>([]);
    const [modalModel, setModalModel] = useState<ModalType>(ModalTypeInit);

    useEffect(() => {
        if (!isMount) {
            Mapper.updateExistingModelFormattingLabels(inquiryModel, InquiryModel(t, isAuthorisedRoute), setInquiryModel);
        }
    }, [t])

    useEffect(() => {
        if (executorIdParameter > 0) {
            getExecutorName(executorIdParameter);
        }
    }, [executorIdParameter])

    useEffect(() => {
        if (!isMount)
            CountryListStore.initCountryList(lng);
    }, [lng]);

    useEffect(() => {
        if (id)
            loadExistingServiceInquiry(id);
        else
            loadEmpty();
    }, [id])

    useEffect(() => {
        const execSub = ExecutorStore.executorList.subscribe(list => {
            setExecutorList(list);
        })
        CountryListStore.initCountryList(lng);
        const countryListSub = CountryListStore.countryList.subscribe(list => {
            setCountryList(list);
        })

        props.handleSideBar(false);

        const executorIdParam = new URLSearchParams(location.search).get("executor");
        if (executorIdParam != undefined)
            setExecutorIdParameter(Number(executorIdParam));

        return () => {
            execSub.unsubscribe();
            countryListSub.unsubscribe();
        }
    }, [])


    const getExecutorName = async (executorId: number) => {
        const executorName = await API.get(inqUrl + '/account-name/' + executorId);
        setExecutorName(executorName);
    }

    const updateCurrency = (executor: IAccountSettings) => {
        Fmt.setCurrency(executor?.currency ?? "");
        Mapper.updateExistingModelFormattingLabels(inquiryModel, InquiryModel(t, isAuthorisedRoute), setInquiryModel);
    }

    const loadEmpty = async () => {
        ExecutorStore.executorSettings.subscribe(updateCurrency);
        const resp = await API.get(inqUrl + '/drop-downs');
        setControlTypes(resp.compressorControlTypes);
        ExecutorStore.executorSelection.subscribe(executorId => {
            if (isAuthorisedRoute && executorId != undefined && executorId > 0) {
                inquiryModel.executorId.value = executorId;
                getLicenseTypesList(executorId);
            }
        })
    }

    const getLicenseTypesList = async (executorId: number) => {
        API.get(`${process.env.REACT_APP_DEVFESSSERVICE_BASE}account-management/account/${executorId}/licenses-types`).then(resp => {
            setLicenseTypeList(resp);
            validateLicense(inquiryModel, licenseTypeList, resp);
        });
    }

    const updateExecutorId = async (executorId: number) => {
        const updatedModel = inquiryModel;
        updatedModel.executorId.value = executorId;
        const currency = executorList.find(x => x?.id == executorId)?.currency ?? "";
        Fmt.setCurrency(currency);
        updatedModel.costsPerkWh.label = InquiryModel(t)?.costsPerkWh?.label;
        setInquiryModel({ ...updatedModel });
        getLicenseTypesList(executorId);
    }

    const updateLicenseType = (value: number) => {
        inquiryModel.licenseTypeId.isValid = true;
        inquiryModel.licenseTypeId.value = value;
        if (value == Consts.LeakageDetection) {
            const services: Array<keyof IInquiryModel> = ["isCompressedAirEnergyEfficiencyPreAuditSelected", "isAnalysisOfTheCompressedAirGenerationSelected", "isCompressedAirQualityAnalysisSelected", "isAirNetworkAnalysisSelected", "isMachineAnalysisForEnergyEfficiencySelected"];
            services.forEach(s => { if (inquiryModel[s].value) inquiryModel[s].value = false })
        }
        setInquiryModel({ ...inquiryModel });
    }

    const changeCountrySelection = (selected: any) => {
        const updatedModel = inquiryModel;

        if (!isAuthorisedRoute) {
            const currency = countryList.find(x => x.countryCode == selected?.countryCode)?.countryCurrency;
            Fmt.setCurrency(currency ?? "");
            updatedModel.costsPerkWh.label = InquiryModel(t)?.costsPerkWh?.label;
        }

        setInquiryModel({
            ...updatedModel,
            country: {
                ...updatedModel.country,
                value: selected?.countryCode
            },
            postcode: {
                ...updatedModel.postcode,
                value: ""
            }
        });
    }

    const loadExistingServiceInquiry = (id: string) => {
        API.get(reqUrl + '/request/' + id).then(resp => {
            setRawInquiryModel(resp.inquiry);
            setLeakageDetectionInDays(resp.leakageDetectionDays);
            Fmt.setCurrency(resp.inquiry.executor.currency);
            Fmt.setMeasurementSystem(resp.inquiry.executor.measurementSystem?.value);
            const mappedInquiryModel = Mapper.mapDataToModelValues(resp.inquiry, InquiryModel(t, isAuthorisedRoute))
            const mappedCompressors = Mapper.mapArrayDataToArrayModel(resp.inquiry.compressorList, CompressorModel(t));
            mappedInquiryModel.executorId.value = resp.inquiry.executor.id;
            mappedInquiryModel.licenseTypeId.value = resp.inquiry.licenseType?.id;
            setCompressorModel(mappedCompressors);
            setInquiryModel({ ...mappedInquiryModel });
            setControlTypes(resp.controlTypes);
            setLicenseTypeList(resp.licenseTypes);
            ExecutorStore.setExecutorSelection(resp?.inquiry?.executor?.id);
        })
    }

    const updateModelHandler = (property: string, value: any) => {
        const updatedModel = Mapper.updateModel(property, value, inquiryModel)
        const checkedModel = markOrUnmarkCompressorChecks(property, value, updatedModel, isAuthorisedRoute)
        setInquiryModel({ ...checkedModel });
    }

    const isAnyServiceSelected = (model: IInquiryModel) => {
        const isSelected = isAnyServiceSelectedValidator(model);
        setAtLeastOneServiceSelected(isSelected);
        return isSelected;
    }

    const validateLicense = (model: IInquiryModel, licenses: ILicenseTypeCount[], convertToProject: boolean) => {
        model.licenseTypeId.isValid = true;
        const licence = licenses.find(x => x.id == model.licenseTypeId.value);
        if (convertToProject === true && licence != undefined && licence.count === 0) {
            OpenGlobalSnackbar("danger", t('LicenseNotAvailable'));
            model.licenseTypeId.isValid = false;
            setInquiryModel({ ...model });
            return false;
        }
        setInquiryModel({ ...model });
        return true;
    }

    const finishClickEvent = async (convertToProject = false, preventRedirect = false) => {
        const [isValidCompressors, validatedCompressors] = checkAndValidateModelArray(compressorModel);
        setCompressorModel(validatedCompressors);

        let validatedModel = validateModel(inquiryModel);
        validatedModel = validateExceptionFields(validatedModel);
        setInquiryModel({ ...validatedModel })

        const isValidInquiryModel = isValidModel(validatedModel);
        const isAnyServiceSelectedSectionValid = isAuthorisedRoute ? isAnyServiceSelected(validatedModel) : true;

        if (!validateLicense(validatedModel, licenseTypeList, isAuthorisedRoute && convertToProject === true)) {
            setIsValid(false);
            return;
        }

        if (isValidInquiryModel && isAnyServiceSelectedSectionValid && isValidCompressors && isPolicyAgreedSelected) {
            await saveInquiryToDatabase(inquiryModel, convertToProject, preventRedirect);
        }
        else {
            setIsValid(false);
            OpenGlobalSnackbar("danger", t('ModelInvalidError'))
        }
    }

    const saveInquiryToDatabase = async (model: IInquiryModel, convertToProject: boolean, preventRedirect: boolean) => {
        const inquiry: IInquiry & { compressorList?: ICompressorModel[] } = Mapper.extractValuesFromModel(model);
        inquiry.compressorList = Mapper.extractValuesFromModelOfArray(compressorModel);

        if (!isAuthorisedRoute) {
            if (inquiry.isCompressedAirEnergyEfficiencyPreAuditSelected) {
                inquiry.isAnalysisOfTheCompressedAirGenerationSelected = true;
                inquiry.isCompressedAirQualityAnalysisSelected = true;
                inquiry.isAirNetworkAnalysisSelected = true;
                inquiry.isMachineAnalysisForEnergyEfficiencySelected = true;
                inquiry.isLeakageDetectionAndDocumentationSelected = true;
            }
            await createServiceInquiry(inquiry);
            OpenGlobalSnackbar("success", t("ServiceInquiryCreatedSuccessfully"));
            redirectAfterSave();
        }
        else if (isAuthorisedRoute && !convertToProject) {
            const resp = await saveUpdateServiceRequest(inquiry);
            OpenGlobalSnackbar("success", t("ServiceRequestSavedSuccessfully"));
            if (!preventRedirect)
                redirectAfterSave();
            else if (id == undefined)
                history.push(`/request/${resp}`);
        }
        else if (isAuthorisedRoute && convertToProject) {
            const resp = await createProject(inquiry)
            openConvertedToProjectModal(resp);
        }
    }

    const createServiceInquiry = async (modelToPost: IInquiry) => {
        const data = { Inquiry: modelToPost }
        if (executorIdParameter > 0)
            data.Inquiry.executorId = executorIdParameter;
        await API.post(inqUrl + '/inquiry', data)
    }

    const saveUpdateServiceRequest = async (modelToPost: IInquiry & { compressorList?: ICompressorModel[] }) => {
        const method = !id ? "post" : "put";
        const data = {
            InquiryDTO: modelToPost,
            CompressorListDTO: modelToPost.compressorList,
            inquiryRoute: isAuthorisedRoute
        }
        return API({
            url: `${reqUrl}/request/${!id ? 0 : id}`,
            method,
            data
        });
    }

    const createProject = async (modelToPost: IInquiry & { compressorList?: ICompressorModel[] }) => {
        const data = {
            InquiryDTO: modelToPost,
            CompressorListDTO: modelToPost.compressorList
        }
        return API.post(`${reqUrl}/request/${!id ? 0 : id}/create-project`, data).then((resp: IConvertToProjectResponse) => {
            OpenGlobalSnackbar("success", t("InquiryServiceConvertedToProjectSuccessfully"))
            return resp;
        });
    }

    const goToHomePage = () => history.push(`/`);
    const goToThankYouPage = () => history.push(`/ThankYou`);
    const goToProjectsPage = () => history.push(`/projectsandrequests`);

    const goToProjectPageById = (id: number) => history.push(`/project/${id}/PS`);

    const cancelClickEvent = () => location.pathname.includes('contact') ? goToHomePage() : goToProjectsPage();
    const redirectAfterSave = () => location.pathname.includes('contact') ? goToThankYouPage() : goToProjectsPage();


    const sendCompressorsToInquiryPage = (compressors: any) => {
        setCompressorModel([...compressors])
    }

    const openConvertedToProjectModal = (response: IConvertToProjectResponse) => {
        setModalModel({
            open: true,
            okText: t('Ok'),
            message: `${t('NewProjectCreated')}: ${response?.projectId}`,
            clickOk: () => goToProjectPageById(response?.id)
        });
    }

    const isServiceSelected = useMemo(() => {
        return inquiryModel.isCompressedAirEnergyEfficiencyPreAuditSelected.value
            || inquiryModel.isAnalysisOfTheCompressedAirGenerationSelected.value
            || inquiryModel.isCompressedAirQualityAnalysisSelected.value
            || inquiryModel.isAirNetworkAnalysisSelected.value
            || inquiryModel.isMachineAnalysisForEnergyEfficiencySelected.value
            || inquiryModel.isLeakageDetectionAndDocumentationSelected.value
    }, [inquiryModel]);

    const postcodeMask = React.useMemo(() => {
        const country = countryList.find(x => x.countryCode === inquiryModel.country.value);
        updateModelHandler("postcodeRegex", country?.countryPostalRegex);
        return Fmt.CountryToPostalMask(country);
    }, [countryList, inquiryModel.country.value]);

    const MemoizedCheckboxComponent = React.memo((props: ICheckboxComponent) =>
        <CheckboxComponent
            size="small"
            labelStyle={{ fontSize: "0.85rem !important", textWrap: 'nowrap' }}
            {...props}
        />
    );
    MemoizedCheckboxComponent.displayName = 'MemoizedCheckboxComponent';

    const InquiryServiceSelection = () => { //isAuthorisedRoute true
        return <Grid xs={12} container>
            <Grid xs={12} pb={2}>
                <Typography fontSize={'1rem !important'}>{t('SelectTheDesiredService')}</Typography>
            </Grid>
            {(inquiryModel.isFreeCalculationOfLeakagesAndSavingsPotentialSelected.value || inquiryModel.isFreeTestLicenseForFestoEnergySavingsServicesSelected.value) &&
                <>
                    <Grid xs={12}>
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isFreeCalculationOfLeakagesAndSavingsPotentialSelected}
                            onChange={e => { updateModelHandler("isFreeCalculationOfLeakagesAndSavingsPotentialSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isFreeTestLicenseForFestoEnergySavingsServicesSelected}
                            onChange={e => { updateModelHandler("isFreeTestLicenseForFestoEnergySavingsServicesSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                </>
            }
            {licenseTypeList.length > 0 ?
                <Grid xs={12} width={'100%'}>
                    <SingleSelectComponent2
                        model={inquiryModel.licenseTypeId}
                        listFromDb={_.cloneDeep(licenseTypeList).map(item => {
                            item.value = (item.count > -1 ?
                                (item.id == Consts.FullAudit ? t("LicenseFullAudit") : t("LicenseLeakageDetection")) + ` (${item.count})` :
                                (item.id == Consts.FullAudit ? t("SubscriptionFullAudit") : t("SubscriptionLeakageDetection")) + ` (${t("ActiveUntil")} ${DateHelper.FormatDate(item.expirationTime)})`);
                            return item;
                        })}
                        onChange={(e) => updateLicenseType(e.target.value as number)}
                        variant={'standard'} />
                </Grid>
                :
                <Grid xs={12}>
                    <LinkComponent
                        newTab
                        link={`/SettingsPanel/ExecutorManagement/${inquiryModel.executorId.value}/AddEditExecutor/`}
                        innerComponent={t('NoLicenseAvailable')}
                    />
                </Grid>
            }
            {inquiryModel?.licenseTypeId?.value == Consts.FullAudit &&
                <>
                    <Grid xs={12}>
                        <MemoizedCheckboxComponent
                            onChange={e => { updateModelHandler("isCompressedAirEnergyEfficiencyPreAuditSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                            model={inquiryModel.isCompressedAirEnergyEfficiencyPreAuditSelected}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isAnalysisOfTheCompressedAirGenerationSelected}
                            onChange={e => { updateModelHandler("isAnalysisOfTheCompressedAirGenerationSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isCompressedAirQualityAnalysisSelected}
                            onChange={e => { updateModelHandler("isCompressedAirQualityAnalysisSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isAirNetworkAnalysisSelected}
                            onChange={e => { updateModelHandler("isAirNetworkAnalysisSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <MemoizedCheckboxComponent
                            model={inquiryModel.isMachineAnalysisForEnergyEfficiencySelected}
                            onChange={e => { updateModelHandler("isMachineAnalysisForEnergyEfficiencySelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                        />
                    </Grid>
                </>
            }
            {inquiryModel?.licenseTypeId?.value > 0 &&
                <Grid xs={12}>
                    <MemoizedCheckboxComponent
                        model={inquiryModel.isLeakageDetectionAndDocumentationSelected}
                        onChange={e => { updateModelHandler("isLeakageDetectionAndDocumentationSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                    />
                </Grid>
            }
        </Grid>
    }

    const ContactServiceSelection = () => {  //isAuthorisedRoute false
        return <Grid xs={12} container>
            <Grid xs={12} pb={2}>
                <Typography fontSize={'1rem !important'}>{t('SelectTheDesiredService')}</Typography>
            </Grid>
            <Grid xs={12}>
                <MemoizedCheckboxComponent
                    model={inquiryModel.isFreeCalculationOfLeakagesAndSavingsPotentialSelected}
                    onChange={e => { updateModelHandler("isFreeCalculationOfLeakagesAndSavingsPotentialSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                />
            </Grid>
            <Grid xs={12}>
                <MemoizedCheckboxComponent
                    model={inquiryModel.isFreeTestLicenseForFestoEnergySavingsServicesSelected}
                    onChange={e => { updateModelHandler("isFreeTestLicenseForFestoEnergySavingsServicesSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                />
            </Grid>
            <Grid xs={12}>
                <MemoizedCheckboxComponent
                    model={inquiryModel.isCompressedAirEnergyEfficiencyPreAuditSelected}
                    onChange={e => { updateModelHandler("isCompressedAirEnergyEfficiencyPreAuditSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                />
            </Grid>
            <Grid xs={12}>
                <MemoizedCheckboxComponent
                    model={inquiryModel.isLeakageDetectionAndDocumentationSelected}
                    onChange={e => { updateModelHandler("isLeakageDetectionAndDocumentationSelected", e.target.checked); setAtLeastOneServiceSelected(true); }}
                />
            </Grid>
        </Grid>
    }
    return (
        <div>
            <ModalComponent model={modalModel} />
            <ImportFullDialog
                open={importOpen}
                setOpen={setImportOpen}
                viewList={[Consts.ProjectImportCompressor]}
                inquiry
                reloadData={(id: string) => id === undefined ?
                    window.location.reload() :
                    window.location.href = `${window.location.origin}/request/${id}`
                }
            />
            <div className="top-bar-container">
                <Grid className="top-bar" container>
                    <BreadcrumbsComponent showOnlyHome={!isAuthorisedRoute} />
                </Grid>
            </div>
            <div className="inquiry">
                <Grid container spacing={2} marginTop={4}>
                    {isAuthorisedRoute == false ?
                        <Grid xs={7}>
                            <HeadingComponent value={t('InquiryFestoSubsidiaryText2')} size="h6" display="inline" />
                            <Typography paddingTop={2}>
                                {t('InquiryFestoSubsidiaryText3')}
                            </Typography>
                        </Grid> : <></>
                    }
                    <Grid container xs={7} spacing={2} rowSpacing={3}>
                        {isAuthorisedRoute &&
                            <>
                                <Grid xs={12}>
                                    <HeadingComponent value={t('CustomerInformation')} size="h6" display="inline" />
                                </Grid>
                                <Grid xs={6}>
                                    <TextFieldComponent
                                        t={t}
                                        inputProps={{ tabIndex: 1 }}
                                        model={inquiryModel.customerNumber}
                                        onChange={e => {
                                            updateModelHandler("customerNumber", e.target.value);
                                        }} />
                                </Grid>
                                <Grid xs={6} alignContent={'end'}>
                                    <ButtonComponent id="IQ-import" customStyle={{ margin: 0 }} value={t("ImportCustomerData")} color="primary" onChange={() => setImportOpen(true)} />
                                </Grid>
                            </>
                        }
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 2 }}
                                model={inquiryModel.companyName}
                                onChange={e => {
                                    updateModelHandler("companyName", e.target.value)
                                }} />
                        </Grid>
                        <Grid xs={6}>
                            <SingleSelectComponent2
                                t={t}
                                tabIndex={8}
                                model={inquiryModel.salutation}
                                listFromDb={Salutations}
                                onChange={(e) => updateModelHandler('salutation', e.target.value as number)}
                                variant={'standard'} />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 3 }}
                                model={inquiryModel.address}
                                onChange={e => {
                                    updateModelHandler("address", e.target.value)
                                }} />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 9 }}
                                model={inquiryModel.firstName}
                                onChange={e => updateModelHandler("firstName", e.target.value)}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 4 }}
                                model={inquiryModel.city}
                                onChange={e => {
                                    updateModelHandler("city", e.target.value)
                                }} />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 10 }}
                                model={inquiryModel.lastName}
                                onChange={e => updateModelHandler("lastName", e.target.value)}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <AutocompleteDropdown
                                tabIndex={5}
                                model={inquiryModel.country}
                                label={t('Country')}
                                options={countryList}
                                optionName="countryName"
                                optionKey="countryCode"
                                onChange={(e: any) => changeCountrySelection(e)}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                inputProps={{ tabIndex: 11 }}
                                model={inquiryModel.email}
                                onChange={e => updateModelHandler("email", e.target.value)}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextFieldComponent
                                t={t}
                                model={inquiryModel.postcode}
                                disabled={inquiryModel.country?.value?.length <= 0 || inquiryModel.country?.value === undefined}
                                InputLabelProps={{ shrink: inquiryModel.country?.value?.length > 0 && inquiryModel.country?.value !== undefined }}
                                InputProps={{
                                    inputComponent: IMaskInput,
                                    inputProps: {
                                        tabIndex: 6,
                                        mask: postcodeMask,
                                        unmask: 'typed',
                                        lazy: (inquiryModel.country?.value?.length <= 0 || inquiryModel.country?.value === undefined) || !inquiryModel.postcodeRegex?.value,
                                        placeholderChar: '#',
                                        definitions: {
                                            '#': /[0-9]/,
                                            '@': /[a-zA-Z]/,
                                        },
                                        onAccept: (e: any) => {
                                            updateModelHandler("postcode", e);
                                        }
                                    },
                                }}
                            />
                        </Grid>
                        <Grid xs={6} id="inquiry-phone-input-grid-item">
                            <PhoneNumberInputField
                                tabIndex={12}
                                model={inquiryModel.phoneNumber}
                                onChange={(e: any) => updateModelHandler("phoneNumber", e)}
                            />
                        </Grid>
                        <Grid xs={6} paddingRight={2}>
                            <TextareaAutosize tabIndex={7} value={inquiryModel.comment.value} placeholder={t('Comment')} minRows={2}
                                onChange={e => updateModelHandler("comment", e.target.value)}
                            />
                        </Grid>
                        <Grid xs={6} container direction="column" alignItems="center">
                            <Grid className="service-selection" width={'100%'}>
                                {isAuthorisedRoute ? <InquiryServiceSelection /> : <ContactServiceSelection />}
                                {(isAuthorisedRoute && !isAtLeastOneServiceSelected) && <Grid xs={12} id="check-service-error"><Typography fontSize={'0.9rem !important'} color={'#D50000'}>{t('AtleastOneServiceMustBeSelected')}</Typography></Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isAuthorisedRoute &&
                    <>
                        <Grid container spacing={0} direction="row" marginTop={4}>
                            <Grid>
                                <MemoizedCheckboxComponent
                                    model={inquiryModel.isFollowUpApproved}
                                    onChange={e => {
                                        updateModelHandler("isFollowUpApproved", e.target.checked)
                                    }}
                                />
                            </Grid>
                            <Grid className="followUp-link">
                                {t('ContactPageFollowUpAgreement')}
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} direction="row">
                            <Grid>
                                <MemoizedCheckboxComponent
                                    model={inquiryModel.isTermsAndConditionsApproved}
                                    onChange={e => {
                                        updateModelHandler("isTermsAndConditionsApproved", e.target.checked)
                                        setIsPolicyAgreedSelected(e.target.checked)
                                    }}
                                />
                            </Grid>
                            <Grid className="policy-link">
                                {t('PrivacyPolicyText')}
                                <LinkComponent
                                    newTab
                                    link={getPrivacyPolicyLink(lng)}
                                    innerComponent={' ' + t('PrivacyPolicyLink')}
                                />
                                {'.*'}
                            </Grid>
                            {isAuthorisedRoute == false && isValid == false && isPolicyAgreedSelected == false &&
                                <Grid xs={12}>
                                    <HelperTextComponent error={true} value={t('InquiryErrorNotAccedptedPolicy')} />
                                </Grid>}
                        </Grid>
                    </>
                }
                {(isAuthorisedRoute || inquiryModel.isFreeCalculationOfLeakagesAndSavingsPotentialSelected.value) &&
                    <Grid container spacing={2} marginTop={2}>
                        <Grid xs={10} container columnSpacing={8}>
                            <Grid xs={12}>
                                <HeadingComponent value={t('AdditionalInfo')} size="h6" />
                            </Grid>
                            <Grid xs={3}>
                                <SingleSelectComponent2
                                    noFirstEmpty
                                    model={inquiryModel.measurementSystem}
                                    listFromDb={MeasurementSystems}
                                    t={t}
                                    onChange={(e) => {
                                        Mapper.updateModelUnitData("measurementSystem", MeasurementSystems.find(x => x.id == e.target.value), inquiryModel, setInquiryModel, InquiryModel, t, isAuthorisedRoute);
                                        Mapper.updateArrayFormattingLabels(compressorModel, CompressorModel(t), setCompressorModel);
                                    }}
                                    variant={'standard'} />
                            </Grid>
                        </Grid>
                        <Grid xs={10} container columnSpacing={8}>
                            <Grid xs={12} pt={4}>
                                <HeadingComponent value={t('ContactPageEnergyCostAndCapacityUtilization')} size="h6" />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.costsPerkWh} onChange={e => {
                                    updateModelHandler("costsPerkWh", InputRestrictions.Decimal(e.target.value, 2))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <TextFieldComponent t={t} model={inquiryModel.mondayToFridayWorkingTime} onChange={e => {
                                    updateModelHandler("mondayToFridayWorkingTime", e.target.value)
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <TextFieldComponent t={t} model={inquiryModel.saturdayWorkingTime} onChange={e => {
                                    updateModelHandler("saturdayWorkingTime", e.target.value)
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <TextFieldComponent t={t} model={inquiryModel.sundayWorkingTime} onChange={e => {
                                    updateModelHandler("sundayWorkingTime", e.target.value)
                                }} />
                            </Grid>
                        </Grid>
                        <Grid xs={12} pt={4}>
                            <HeadingComponent value={t('AmbientClimaticConditionsHeader')} size="h6" />
                        </Grid>
                        <Grid xs={10} container columnSpacing={8} rowSpacing={3} className="ambient-climatic-conditions-table">
                            <Grid container xs={12} columnSpacing={8}>
                                <Grid xs={3}>
                                    <NumberInputFieldComponent t={t} model={inquiryModel.altitudeAboveSeaLevel} onChange={e => {
                                        updateModelHandler("altitudeAboveSeaLevel", InputRestrictions.Decimal(e.target.value))
                                    }} />
                                </Grid>
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.temperatureSpring} onChange={e => {
                                    updateModelHandler("temperatureSpring", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.temperatureSummer} onChange={e => {
                                    updateModelHandler("temperatureSummer", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.temperatureAutumn} onChange={e => {
                                    updateModelHandler("temperatureAutumn", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.temperatureWinter} onChange={e => {
                                    updateModelHandler("temperatureWinter", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.humiditySpring} onChange={e => {
                                    updateModelHandler("humiditySpring", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.humiditySummer} onChange={e => {
                                    updateModelHandler("humiditySummer", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.humidityAutumn} onChange={e => {
                                    updateModelHandler("humidityAutumn", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                            <Grid xs={3}>
                                <NumberInputFieldComponent t={t} model={inquiryModel.humidityWinter} onChange={e => {
                                    updateModelHandler("humidityWinter", InputRestrictions.Decimal(e.target.value))
                                }} />
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <CheckboxComponent
                                model={inquiryModel.isTheAirSupllyShuttedOff}
                                onChange={e => updateModelHandler("isTheAirSupllyShuttedOff", e.target.checked)}
                            />
                        </Grid>
                        <Grid xs={12} pt={4}>
                            <HeadingComponent value={t('Compressors')} size="h6" />
                        </Grid>
                        <Grid xs={12} >
                            <CompressorTable
                                compact={true}
                                controlTypes={controlTypes}
                                sendCompressorsToInquiryPage={sendCompressorsToInquiryPage}
                                compressorList={compressorModel}
                            />
                        </Grid>
                        {!isAuthorisedRoute && executorIdParameter > 0 &&
                            <Grid xs={4}>
                                <TextFieldComponent
                                    disabled
                                    t={t}
                                    model={{ value: executorName, idkey: inquiryModel.executorId.idkey, label: inquiryModel.executorId.label, isValid: inquiryModel.executorId.isValid, validationError: inquiryModel.executorId.validationError, validators: [] }}
                                />
                            </Grid>
                        }
                        {isAuthorisedRoute &&
                            <Grid xs={12} container spacing={2} marginTop={4} direction="column">
                                <Grid md={3}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={inquiryModel.staticConsumption}
                                        onChange={e => updateModelHandler("staticConsumption", InputRestrictions.Decimal(e.target.value))}
                                    />
                                </Grid>
                                <Grid md={3}>
                                    <NumberInputFieldComponent
                                        t={t}
                                        model={inquiryModel.leakageLevel}
                                        onChange={e => updateModelHandler("leakageLevel", InputRestrictions.Decimal(e.target.value))}
                                    />
                                </Grid>
                                <Grid md={4} pt={4}>
                                    <HeadingComponent value={t('EstimatedLeakageDetectionDays') + " " + (leakageDetectionInDays)} size="h3" />
                                </Grid>
                                <Grid md={4} pt={4}>
                                    <HeadingComponent value={t('ExecutorSelection')} size="h3" />
                                </Grid>
                                <Grid xs={4}>
                                    <SingleSelectComponent2
                                        model={inquiryModel.executorId}
                                        t={t}
                                        listFromDb={executorList.filter(e => e.id > 0)}
                                        onChange={(e) => updateExecutorId(e.target.value as number)}
                                        noFirstEmpty={true}
                                        variant={'standard'} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                }
                <Grid container spacing={1} marginTop={8} marginBottom={4} direction="row" justifyContent="space-between">
                    <Grid justifyContent="flex-start">
                        <Grid paddingTop={2}>
                            <HelperTextComponent error={true} value={t('FieldsWithAsteriskAreMandatory')} />
                        </Grid>
                    </Grid>
                    <Grid justifyContent="flex-end">
                        <ButtonComponent id='INQUiRY-cancel' value={t('Cancel')} color="secondary" onChange={cancelClickEvent}></ButtonComponent>
                        <ButtonComponent id='INQUiRY-save' value={t('Save')} color="primary" onChange={() => finishClickEvent()}></ButtonComponent>
                        {isServiceSelected && isAuthorisedRoute &&
                            <PdfReportLeakageEstimation
                                rawInquiryModel={rawInquiryModel}
                                inquiryModel={inquiryModel}
                                setInquiryModel={setInquiryModel}
                                compressorModel={compressorModel}
                                finishClickEvent={finishClickEvent}
                                isAnyServiceSelected={isAnyServiceSelected}
                            />
                        }
                        {isServiceSelected && isAuthorisedRoute &&
                            <ButtonComponent
                                id='INQUiRY-start-project'
                                value={t('StartProject')}
                                color="primary"
                                onChange={() => finishClickEvent(true)}
                            />
                        }
                    </Grid>
                </Grid>
            </div >
        </div>
    );
}