import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImageViewer from '../ImageViewer/ImageViewer';
import { useParams, useHistory } from "react-router-dom";
import ConsumptionTable from './ConsumptionTable'
import RepairTable from './RepairTable'
import AnnualSavingTable from './AnnualSavingTable'
import { Grid, TextareaAutosize } from '@mui/material';
import { HeadingComponent, ButtonComponent } from '../Reusable/';
import { API } from '../../helper/ApiHelper';
import PaybackTimeChart from './PaybackTimeChart';
import CostsPerUnit from "./CostsPerUnit";
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { DirtyStore } from '../../store';

export default function LeakageAnalysis(props) {
    const { denyEdit } = props;
    const laUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "leakage-analysis";
    const { id } = useParams();
    let history = useHistory();
    const { t } = useTranslation();
    const [model, setModel] = useState({});
    const [conumptionModel, setConsumptionModel] = useState([])
    const [repairModel, setRepairModel] = useState([])
    const [annualSavingsModel, setAnnualSavingsModel] = useState({})
    const [paybackChartModel, setPaybackChartModel] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        API.get(laUrl + '/' + id).then(res => {
            setModel(res);
            setConsumptionModel(res.leakageAnalysisCaclResult.consumptionCalcResults)
            setRepairModel(res.leakageAnalysisCaclResult.repairCalcResults)
            setAnnualSavingsModel(res.leakageAnalysisCaclResult.annualSavingsCalcResult)
            setPaybackChartModel(res.leakageAnalysisCaclResult.paybackChartResults)
        });
    };

    function saveForm() {
        var data = {
            ProjectId: id,
            Comment: model.comment
        };
        API.put(laUrl + '/' + id, data).then(() => {
            DirtyStore.setIsDirty(false);
            OpenGlobalSnackbar("success", t('SaveSuccessful'));
            fetchData();
        });
        return true;
    }

    const clickCancel = () => {
        history.push(`/projectsandrequests`);
    }

    return (
        <div className="leakage-analysis table-layout-fix">
            <Grid container spacing={5} direction="column">
                <Grid item id="#CostsPerUnit">
                    <HeadingComponent value={t('CostsPerUnit')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <CostsPerUnit CostsPerUnit={model.leakageAnalysisCaclResult} />
                </Grid>
                <Grid item id="#LeakageLevelCosts">
                    <HeadingComponent value={t('LeakageLevelCosts')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <ConsumptionTable Consumptions={conumptionModel} />
                </Grid>
                <Grid item id="#LeakageDetectionAndRepairCosts">
                    <HeadingComponent value={t('LeakageDetectionAndRepairCosts')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <RepairTable Repairs={repairModel} />
                </Grid>
                <Grid item id="#AnnualSavingsAfterTaxes">
                    <HeadingComponent value={t('AnnualSavingsAfterTaxes')} size="h6" />
                </Grid>
                <Grid item>
                    <AnnualSavingTable AnnualSavings={annualSavingsModel} />
                </Grid>
                <Grid item id="#PaybackTime">
                    <HeadingComponent value={t('PaybackTime')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <PaybackTimeChart ChartModel={paybackChartModel} />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} id="textarea_LA-comment" type="text" value={model.comment} minRows="5" onChange={e => {
                        model.comment = e.target.value;
                        setModel({ ...model });
                        DirtyStore.setIsDirty(saveForm);
                    }} />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.LeakageAnalysis} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id="LA-cancel" value={t('Cancel')} color="secondary" onChange={clickCancel}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="LA-save" value={t('Save')} color="primary" onChange={saveForm}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
