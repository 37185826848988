import { useState, useEffect } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { getFontName } from '../../helper/Pdf';
import { getLocale } from '../../helper/CountryLanguages';

export const usePdfStyles = () => {
    const [style, setStyle] = useState({});
    const { lng } = getLocale();
    useEffect(() => {
        let styleTemp = StyleSheet.create({
            page: {
                fontFamily: getFontName(lng),
                flexDirection: 'row',
                backgroundColor: '#FFFFFF',
                flexWrap: 'wrap',
                paddingTop: '13mm',
                paddingBottom: '13mm',
                paddingLeft: '7mm',
                paddingRight: '9mm',
                justifyContent: "space-between",
            },
            pageLetter: {
                fontFamily: getFontName(lng),
                flexDirection: 'row',
                backgroundColor: '#FFFFFF',
                flexWrap: 'wrap',
                paddingTop: 48,
                paddingBottom: 51,
                paddingLeft: 27,
                paddingRight: 27,
                justifyContent: "space-between",
            },
            logoWrapper: {
                width: 55,
                height: 25
            },
            logo: {
                objectPosition: "center",
                objectFit: "contain",
                width: 69,
                height: 25
            },
            qrCode: {
                flexGrow: 1,
                flexBasis: 0,
            },
            section: {
                width: 173,
                height: 96,
                fontSize: 4,
                justifyContent: "center"
            },
            sectionLetter: {
                width: 177,
                height: 90,
                fontSize: 4,
                justifyContent: "center",
            },
            boldText0: {
                fontFamily: getFontName(lng, true),
                fontSize: 7,
                textAlign: 'center',

            },
            boldText1: {
                fontFamily: getFontName(lng, true),
                fontSize: 5,
                textAlign: 'center',

            },
            qrCodeText: {
                fontFamily: getFontName(lng, true),
                fontSize: 12,
                textAlign: 'center'
            },
            underlineText: {
                textDecoration: "underline",
            },
            spacer0: {
                height: 50
            },
            spacer: {
                height: 10
            },
            spacer1: {
                height: 2
            },
            spacer2: {
                height: 2
            },
            table: {
                display: "table",
            },
            tableRow: {
                flexDirection: "row",
                alignItems: "center"
            },

            tableCol1: {
                flexGrow: 1.5,
                flexBasis: 0,
                textAlign: 'center'
            },
            tableCol2: {
                flexGrow: 1,
                flexBasis: 0,
                textAlign: 'center'
            },

        });
        setStyle(styleTemp);
    }, [lng]);

    return style;
}
