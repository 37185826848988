import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import * as Mapper from '../../helper/Mappers';
import { HeadingComponent, ButtonComponent } from '../Reusable/'
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Container, TablePagination } from '@mui/material';
import { API } from '../../helper/ApiHelper';
import { QrCodesAuditModel, QrCodesAuditModelHeadings } from './QrcodeAuditModel';
import BreadcrumbsComponent from "../Breadcrumbs/Breadcrumbs";
import { getLink } from '../../helper/EditLinkHelper';
import TableHeadingContent from '../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel"

export default function QrCodeAudit(props) {
    const getUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "qr-code";
    const { t } = useTranslation();
    let { id } = useParams();
    let history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [qrCodes, setQrCodes] = useState([QrCodesAuditModel]);
    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        if (id) {
            loadExistingData(id);
        }
    }, [id]);

    useEffect(() => {
        props.handleSideBar(true);
    }, []);

    const clickCancel = () => {
        history.push(`/project/${id}/PS`);
    }

    const loadExistingData = (id) => {
        API.get(`${getUrl}/audit/${id}`).then(resp => {
            let mappedParts = [];
            resp.qrCodeAudit.forEach(item => {
                let part = Mapper.mapDataToModelValues(item, QrCodesAuditModel);
                part.service.value = t(item.service);
                part.reason.value = t(item.reason);
                mappedParts.push(part);
            });
            setQrCodes(mappedParts);
            setFilterData(mappedParts);
        })
    }

    const filterFunction = (tempdata) => {
        setQrCodes(tempdata);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <div>
        <Grid className="top-bar breadcrumb-container" container direction="row">
            <BreadcrumbsComponent />
        </Grid>
        <Container>
            <div className="single-table-page" >
                <Grid container direction="column">
                    <Grid item>
                        <HeadingComponent value={t('QrCodeAudit')} size="h6" />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ margin: "15px 0" }}>
                        <TableHeadingContent
                            defaultData={qrCodes}
                            filterData={filterData}
                            onFilterFunction={filterFunction}
                            filterModel={FilterModel.QrCodeAudit}
                            NotShowAddDelete={true}
                            tableId={"QrCodeAudit"}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {
                                            QrCodesAuditModelHeadings.map((el, fieldIndex) => {
                                                return <TableCell key={'heading' + el + fieldIndex} className="table-rightborder">
                                                    {t(el)}
                                                </TableCell>
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        qrCodes.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage)).map((part, rowIndex) =>
                                            <TableRow key={rowIndex}>
                                                <TableCell id={"QRCODEAUDIT-LINK-" + part.id.value + rowIndex} className="table-rightborder">
                                                    <a href={getLink(id, part.id.value, part.type.value)}
                                                        onClick={(e) => { e.preventDefault(); history.push(getLink(id, part.id.value, part.type.value)) }}>{part.service.value}</a>
                                                </TableCell>
                                                <TableCell id={"QRCODEAUDIT-" + part.no.value + rowIndex} className="table-rightborder">{part.no.value}</TableCell>
                                                <TableCell id={"QRCODEAUDIT-" + part.measurementId.value + rowIndex} className="table-rightborder">{part.measurementId.value}</TableCell>
                                                <TableCell id={"QRCODEAUDIT-" + part.reason.value + rowIndex} className="table-rightborder">{part.reason.value}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                            {qrCodes.length > 0 ?
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    component="div"
                                    count={qrCodes && qrCodes.length > 0 ? qrCodes.length : 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                                : null
                            }
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid container direction="row" justifyContent="flex-end" style={{ marginTop: "15px" }}>
                            <Grid item><ButtonComponent id="QRCODEAUDIT-cancel" value={t('Cancel')} color="primary" onChange={clickCancel}></ButtonComponent></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </div>
}

