import { BehaviorSubject } from "rxjs";
import { ExecutorStore } from './AccountStore';
import { API } from '../helper/ApiHelper';
import { authContext, adalConfig, replaceAuth } from '../authConfig';
import { AuthContextProps } from "react-oidc-context";

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    accountType?: string;
    userRights?: IUserRights;
}

export interface IUserRights {
    isSuperAdmin?: boolean;
    isTechnician?: boolean;
    isReader?: boolean;
    isEditor?: boolean;
    isExecutorAdmin?: boolean;
}

const user = new BehaviorSubject<IUser>({});
const token = new BehaviorSubject<string>("");
const tokenExpiryDate = new BehaviorSubject(Date.now());
const userLoggedIn = new BehaviorSubject(false);
const uumUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "uum/users";
const uumMigration = process.env.REACT_APP_UUM_MIGRATION as string;
let adalTimeout: NodeJS.Timeout | string | number | undefined = undefined;
let adalExpiryCheckTimeout: NodeJS.Timeout | string | number | undefined = undefined;

const getTokenFromCache = () => authContext.getCachedToken(adalConfig.clientId);

const setTokenRefresh = (oidcContext?: AuthContextProps) => {
    clearTimeout(adalExpiryCheckTimeout);
    adalExpiryCheckTimeout = setTimeout(() => setTokenRefresh(oidcContext), 5000);

    if (!document.hidden && token.value != undefined && token.value.length > 0 && Date.now() > tokenExpiryDate.value)
        checkAuth(oidcContext);
}

const checkAuth = async (oidcContext?: AuthContextProps) => {
    if (replaceAuth) {
            clearTimeout(adalTimeout);
            adalTimeout = setTimeout(() => checkAuth(oidcContext), 3000);
            const isTokenStillValid = oidcContext?.user?.expires_at != undefined && oidcContext?.user?.expires_at * 1000 > Date.now();
            if (isTokenStillValid) {
                clearTimeout(adalTimeout);
                const user = await oidcContext?.signinSilent();
                if (user) {
                    token.next(user.access_token);
                    const tokenExpirationDate = (JSON.parse(atob(user.access_token.split('.')[1]))).exp * 1000;
                    tokenExpiryDate.next(tokenExpirationDate);
                    initializeUser();
                    setTokenRefresh(oidcContext);
                }
            }
            else if (!oidcContext?.isLoading && !oidcContext?.activeNavigator && !document.hidden) {
                sessionStorage.setItem('pathname', window.location.pathname);
                oidcContext?.signinRedirect();
            }
    }
    else {
        clearTimeout(adalTimeout);
        adalTimeout = setTimeout(checkAuth, 3000);
        const tokenFromCache = getTokenFromCache();
        const isTokenStillValid = tokenFromCache != undefined && (JSON.parse(atob(tokenFromCache.split('.')[1]))).exp * 1000 > Date.now();
        if (isTokenStillValid) {
            clearTimeout(adalTimeout);
            authContext.acquireToken(
                process.env.REACT_APP_CLIENT_ID as string,
                () => {
                    const tok = sessionStorage.getItem('adal.idtoken');
                    if (tok != undefined) {
                        token.next(tok);
                        const tokenExpirationDate = (JSON.parse(atob(tok.split('.')[1]))).exp * 1000;
                        tokenExpiryDate.next(tokenExpirationDate);
                        initializeUser();
                        setTokenRefresh(oidcContext);
                    }
                }
            );
        }
        else if (!authContext.loginInProgress() && !document.hidden) {
            authContext.login()
        }
    }
}

const initializeUser = () => {
    if (userLoggedIn.value == false) {
        UserStore.initUser();
        userLoggedIn.next(true);
    }
}

const UserStore = {
    user,
    token,
    userLoggedIn,
    loginUser: async (oidcContext?: AuthContextProps) => {
        if (replaceAuth) {
            if (token.value != undefined && token.value.length > 0) {
                initializeUser();
            }
            else {
                clearTimeout(adalTimeout);
                adalTimeout = setTimeout(() => checkAuth(oidcContext), 1000);
            }
        }
        else {
            if (token.value != undefined && token.value.length > 0) {
                initializeUser();
            }
            else {
                clearTimeout(adalTimeout);
                adalTimeout = setTimeout(() => checkAuth(oidcContext), 1000);
            }
        }
    },
    setUser: (resp: IUser) => {
        user.next(resp);
        ExecutorStore.initExecutorList();
    },
    initUser: function () {
        API.get<IUser>(uumUrl).then((resp) => {
            this.setUser(resp);
        }).catch((error) => {
            if (!!error.response && error.response.status == 428) {
                window.location.href = uumMigration;
            }
            else
                throw error;
        });
    }
}

export {
    UserStore,
    checkAuth
}