import React from 'react'
import { getLocale } from '../../helper/CountryLanguages';
import { Table, TableCell, TableBody, TableRow, Typography, TableHead, TableContainer, Paper, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Fmt from '../../helper/Formatters'

export default function CostsPerUnit(props) {
    const { country } = getLocale();
    const { t } = useTranslation();
    const { CostsPerUnit } = props;
    return (
        <TableContainer component={Paper}>
            {CostsPerUnit != undefined ? <Table aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell width="20%"></TableCell>
                        <TableCell>{t('EnergyCost') + Fmt.getUnitWithCurrency("powerPerHour", t)}</TableCell>
                        <TableCell>{t('CompressedAirCosts') + Fmt.getUnitWithCurrency("cubicMeterOfGas", t)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={0}>
                        <TableCell>{t('Electricity')}</TableCell>
                        <TableCell id="td_CostsPerUnit-costPerKwh">{Fmt.round(CostsPerUnit.costPerKwh, country, 2)}</TableCell>
                        <TableCell id="td_CostsPerUnit-costPerKwh">{Fmt.round(CostsPerUnit.costElectricityPerm, country, 3)}</TableCell>
                    </TableRow>
                    <TableRow key={1}>
                        <TableCell className="font-bold">{t('TotalCosts')}</TableCell>
                        <TableCell id="td_CostsPerUnit-costElectricityPerkWhTotal" className="font-bold"><div className="hidden">{Fmt.round(CostsPerUnit.costElectricityPerkWhTotal, 2)}</div></TableCell>
                        <Tooltip title={Fmt.round(CostsPerUnit.costPerm, country, 7)} placement="left">
                            <TableCell id="td_CostsPerUnit-costPerm" className="font-bold">
                                {Fmt.round(CostsPerUnit.costPerm, country, 3)}
                            </TableCell>
                        </Tooltip>
                    </TableRow>
                </TableBody>
            </Table>
                : null
            }
            <Typography>
                * {t("InclusinveElectricityMaintenanceInvestmentCosts")}
            </Typography>
        </TableContainer>

    )
}
