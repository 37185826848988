export interface IReplacementPartsCartModel {
    service?: { value: string, isValid: boolean };
    no: { value: number, isValid: boolean };
    measurementId?: { value: number, isValid: boolean };
    building: { value: string, isValid: boolean },
    department: { value: string, isValid: boolean },
    machine: { value: string, isValid: boolean },
    partNumber?: { value: string, isValid: boolean };
    typeCode?: { value: string, isValid: boolean };
    component?: { value: string, isValid: boolean };
    manufacturer?: { value: string, isValid: boolean };
    newPartNumber?: { value: string, isValid: boolean };
    newTypeCode?: { value: string, isValid: boolean };
    newManufacturer?: { value: string, isValid: boolean };
    newComponent?: { value: string, isValid: boolean };
    quantity: { value: string, isValid: boolean };
    orderSparePart: { value: boolean, isValid: boolean };
    replaceWithDifferent: { value: boolean, isValid: boolean };
}

export const ReplacementPartsCartModel: IReplacementPartsCartModel = {
    service: { value: '', isValid: true },
    no: { value: 0, isValid: true },
    measurementId: { value: 0, isValid: true },
    building: { value: "", isValid: true },
    department: { value: "", isValid: true },
    machine: { value: "", isValid: true },
    partNumber: { value: '', isValid: true },
    typeCode: { value: '', isValid: true },
    component: { value: '', isValid: true },
    manufacturer: { value: '', isValid: true },
    newPartNumber: { value: "", isValid: true },
    newTypeCode: { value: "", isValid: true },
    newComponent: { value: "", isValid: true },
    newManufacturer: { value: "", isValid: true },
    quantity: { value: "", isValid: true },
    orderSparePart: { value: false, isValid: true },
    replaceWithDifferent: { value: false, isValid: true }
}

export const ReplacementPartsCartHeadings = ['Service', 'Nr', 'MeasurementId', 'Building', 'Department', 'Machine', 'PartNumber', 'TypeCode', 'Component', 'Manufacturer', 'PartNumber', 'TypeCode', 'Component', 'Manufacturer', 'Quantity', 'OrderSparePart']